export function roomCountString (roomCount) {
  roomCount = parseInt(roomCount)

  if (roomCount === 1) {
    return roomCount.toString() + ' pièce'
  } else {
    return roomCount.toString() + ' pièces'
  }
}

export function propTypeString (type) {
  type = parseInt(type)

  if (type === 0) {
    return 'Appartement'
  } else if (type === 1) {
    return 'Maison'
  } else if (type === 2) {
    return 'Local'
  } else if (type === 3) {
    return 'Duplex'
  } else {
    return 'Autre'
  }
}

export function levelString (level) {
  let levelString = 'R'
  if (level > 0) {
    levelString += '+' + String(Math.abs(level))
  } else if (level < 0) {
    levelString += '-' + String(Math.abs(level))
  } else {
    levelString += '0'
  }

  return levelString
}

export function roomTypeString (type, number) {
  type = parseInt(type)
  let name

  if (type === 0) {
    name = 'Séjour'
  } else if (type === 1) {
    name = 'Chambre'
  } else if (type === 2) {
    name = 'Loggia'
  } else if (type === 3) {
    name = 'Terrasse'
  } else if (type === 4) {
    name = 'Local'
  } else if (type === 5) {
    name = 'Bureau'
  } else {
    name = 'Autre'
  }

  if (number > 0) {
    name = name + ' ' + number.toString()
  }

  return name
}

export function arrayString (array) {
  let text = ''
  for (let i = 0; i < array.length; i++) {
    text += array[i]
    if (i === array.length - 2) {
      text = text + ' et '
    } else if (i < array.length - 2) {
      text = text + ', '
    }
  }
  return text
}

export function floorStringFilter (floor) {
  floor = parseInt(floor)

  if (floor === 0) {
    return 'RDC'
  } else if (floor === 1) {
    return '1er'
  } else {
    return floor.toString() + 'ème'
  }
}

export function floorString (floor) {
  floor = parseInt(floor)

  if (floor === 0) {
    return 'RDC'
  } else if (floor === 1) {
    return '1er étage'
  } else {
    return floor.toString() + 'ème étage'
  }
}

export function orientationString (orientation) {
  if (orientation === 'N') {
    return 'Nord'
  } else if (orientation === 'NE') {
    return 'Nord-Est'
  } else if (orientation === 'E') {
    return 'Est'
  } else if (orientation === 'SE') {
    return 'Sud-Est'
  } else if (orientation === 'S') {
    return 'Sud'
  } else if (orientation === 'SW') {
    return 'Sud-Ouest'
  } else if (orientation === 'W') {
    return 'Ouest'
  } else if (orientation === 'NW') {
    return 'Nord-Ouest'
  } else {
    return ''
  }
}

export function otherString (other) {
  if (other === 'natural_light') {
    return 'Séjour lumineux'
  } else if (other === 'sunlight') {
    return 'Séjour ensoleillé'
  } else if (other === 'exterior') {
    return 'Espace extérieur'
  }
}

export function hourString (hour) {
  if (hour !== null && hour !== undefined) {
    return hour.substring(0, 5).replace(':', 'h')
  } else {
    return '00h00'
  }
}

export function numberString (number) {
  const roundNumber = Math.round(number * 10) / 10
  return roundNumber.toString().replace('.', ',')
}
