<template>
  <div class="map-container">
    <div class="solen-shadow" id="map"></div>
    <div v-if="address" class="solen-tooltip solen-shadow">{{ address }}</div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
  name: 'the-project-page-map',
  props: {
    address: String,
    latitude: Number,
    longitude: Number
  },
  data () {
    return {
      map: null
    }
  },
  methods: {
    initMap () {
      const layerUrl = 'https://api.maptiler.com/maps/e1f234b7-f630-4138-a60a-88432df180a0/{z}/{x}/{y}.png?key=vrAlOl1MAn6qQ5ToaRPR'
      const latitude = this.latitude
      const longitude = this.longitude
      this.map = L.map('map', { preferCanvas: true, detectRetina: true, zoomControl: false, boxZoom: false, keyboard: false, tap: false, doubleClickZoom: false, touchZoom: false, scrollWheelZoom: false, zoomSnap: 0.20, attributionControl: false, dragging: false }).setView([latitude, longitude], 17)
      L.tileLayer(layerUrl).addTo(this.map)
      const solenIcon = L.icon({
        iconUrl: require('../assets/img/picto_map.png'),
        iconAnchor: [24, 48],
        iconSize: [48, 48],
        className: 'no-cursor'
      })
      L.marker([latitude, longitude], { icon: solenIcon }).addTo(this.map)
    }
  },
  mounted () {
    this.initMap()
  },
  beforeUnmount () {
    if (this.map) {
      this.map.remove()
    }
  },
  watch: {
    latitude: function () {
      if (this.map) {
        this.map.remove()
      }
      this.initMap()
    },
    longitude: function () {
      if (this.map) {
        this.map.remove()
      }
      this.initMap()
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solen-tooltip {
  transform: translateY(75%);
  z-index: 9999;
  color: black;
  opacity: 1;
  background-color: white;
  border-radius: 15px;
  padding: 7px 10px;
  font-family: 'VAG Rounded Next', sans-serif;
  font-size: 0.9em;
}
</style>
