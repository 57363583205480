<template>
  <div id="widget">
    <iframe width="100%" height="100%" :src="'https://www.cityscan.fr/widget?clientKey=144142d2afcb766c43cb36012bf13d&id=' + csId"></iframe>
  </div>
</template>

<script>

export default {
  name: 'the-property-page-cs-widget',
  props: {
    csId: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data () {
    return {
      clientKeyApi: '144142d2afcb766c43cb36012bf13d'
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  },
  updated () {
  }
}
</script>

<style scoped>
  #widget {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>
