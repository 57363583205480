<template>
  <div class="modal fade" :class="{show, 'd-block': active}" id="modal" aria-hidden="true" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content modal-content-solen solen-shadow">
        <div class="modal-header modal-header-solen">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" @click="toggleModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <object style="width:100%; height: 100%" :data="pdfUrl" type="application/pdf">
              <embed :src="pdfUrl" type="application/pdf" />
          </object>
        </div>
      </div>
    </div>
  </div>
  <div v-if="active" class="modal-backdrop fade show"></div>
</template>

<script>
export default {
  name: 'the-pdf-viewer',
  props: {
    triggerModal: Boolean,
    pdfUrl: String,
    title: String
  },
  data () {
    return {
      active: false,
      show: false
    }
  },
  methods: {
    toggleModal () {
      const body = document.querySelector('body')
      this.active = !this.active
      this.active
        ? body.classList.add('modal-open')
        : body.classList.remove('modal-open')
      setTimeout(() => (this.show = !this.show), 10)
    }
  },
  watch: {
    triggerModal () {
      this.toggleModal()
    }
  }
}
</script>

<style scoped>
  .modal-content-solen {
    border: none;
    border-radius: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .modal-header-solen {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .modal-footer-solen {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .modal-title {
    font-size: 28px;
  }
</style>
