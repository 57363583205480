<template>
  <div :class="{ 'strong-point': true, 'link': clickable}" :title="title" @click="showMap = !showMap">
    <div :class="['icon', 'cs-icon-ff', csIcon]">
    </div>
    <div class="d-flex flex-column flex-grow-1">
        <div class="title">{{ indicator.legend }}</div>
    </div>
    <div v-if="clickable"><span class="cs-icon icon-open-new-tab"></span></div>
  </div>
  <the-indicator-viewer v-if="clickable" :triggerModal="showMap" :indicator="indicator"></the-indicator-viewer>
</template>

<script>
import TheIndicatorViewer from './TheIndicatorViewer.vue'

export default {
  components: { TheIndicatorViewer },
  name: 'base-indicator',
  props: {
    indicator: Object
  },
  data () {
    return {
      showMap: false
    }
  },
  computed: {
    clickable () {
      if (this.indicator.map !== null) {
        return true
      } else {
        return false
      }
    },
    title () {
      if (this.clickable) {
        return 'Voir les informations détaillées'
      } else {
        return ''
      }
    },
    csIcon () {
      return 'icon-' + this.indicator.id.toString() + '-02'
    }
  }
}
</script>

<style scoped>
  .strong-point {
    border-radius: 10px;
    padding: 6px 10px;
    display: flex;
    text-align: left;
    align-items: center;
  }

  .link {
    cursor: pointer;
  }

  .link:hover {
    background-color: #E5E6EB;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .legend {
    font-size: 13px;
    font-weight: 300;
  }

  .strong-point .icon-open-new-tab {
    color:#1c62b4;
    margin-right: 6px;
  }

  .icon {
    flex: 0 0 26px;
    font-size: 26px;
    margin-right: 15px;
  }

  @media (max-height: 700px) {
    .title {
      font-size: 20px;
      line-height: 24px;
    }

    .legend {
      font-size: 13px;
    }

    .icon {
      flex: 0 0 42px;
      height: 42px;
    }
  }
</style>
