<template>
  <div class="modal fade" :class="{show, 'd-block': active}" id="modal" aria-hidden="true" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content modal-content-solen solen-shadow">
        <div class="modal-header modal-header-solen">
          <div class="d-flex flex-column align-self-center" :style="{color: color}">
            <div class="certiftitle">{{ title }} - {{ roomTypeString(roomType, roomNumber)  }}</div>
            <div class="certifsubtitle">{{ subtitle }}</div>
          </div>
          <button type="button" class="btn-close" @click="toggleModal" aria-label="Close"></button>
        </div>
        <div class="modal-body modal-body-solen">
            <div class="room-values">
                <div class="row no-gutters season-values text-center" :style="{color: color}">
                    <div class="col-6 col-lg-3">
                        <div class="season">Printemps</div>
                        <div ref="spring" class="indicator">
                            <div class="value">{{ hourString(roomData.spring.averages[resultType]) }}</div>
                        </div>
                        <div v-if="showLocaleAverages" class="city">Locale : {{ hourString(seasonData.spring.averages[resultType]) }}</div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="season">Eté</div>
                        <div ref="summer" class="indicator">
                            <div class="value">{{ hourString(roomData.summer.averages[resultType]) }}</div>
                        </div>
                        <div v-if="showLocaleAverages" class="city">Locale : {{ hourString(seasonData.summer.averages[resultType]) }}</div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="season">Automne</div>
                        <div ref="autumn" class="indicator">
                            <div class="value">{{ hourString(roomData.autumn.averages[resultType]) }}</div>
                        </div>
                        <div v-if="showLocaleAverages" class="city">Locale : {{ hourString(seasonData.autumn.averages[resultType]) }}</div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <div class="season">Hiver</div>
                        <div ref="winter" class="indicator">
                            <div class="value">{{ hourString(roomData.winter.averages[resultType]) }}</div>
                        </div>
                        <div v-if="showLocaleAverages" class="city">Locale : {{ hourString(seasonData.winter.averages[resultType]) }}</div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-md-row justify-content-center mt-4">
                    <div class="annual-average d-flex justify-content-center px-3" :style="{color: color}">
                        <div class="circle align-self-center" :style="{ backgroundColor: color}"></div>
                        <div class="align-self-center">Moyenne sur la saison</div>
                    </div>
                    <div v-if="showLocaleAverages" class="city d-flex justify-content-center  px-3">
                        <div class="circle align-self-center"></div>
                        <div class="align-self-center">Moyenne locale</div>
                    </div>
                </div>
            </div>

            <div class="slots mb-4">
                <div class="title">{{ hourTitle }}</div>
                  <div class="d-flex text-center">
                      <div class="flex-grow-1 position-relative" ref="slot-spring">
                          <div class="season-title">Printemps</div>
                      </div>
                      <div class="flex-grow-1 position-relative" ref="slot-summer">
                          <div class="season-title">Eté</div>
                      </div>
                      <div class="flex-grow-1 position-relative" ref="slot-autumn">
                          <div class="season-title">Automne</div>
                      </div>
                      <div class="flex-grow-1 position-relative" ref="slot-winter">
                          <div class="season-title">Hiver</div>
                      </div>
                  </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="active" class="modal-backdrop fade show"></div>
</template>

<script>
import WebFont from 'webfontloader'
import { roomTypeString, hourString } from '../util/filters.js'

export default {
  name: 'the-certificate-viewer',
  props: {
    triggerModal: Boolean,
    resultType: String,
    roomType: Number,
    roomNumber: Number,
    showLocaleAverages: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    roomData: {
      type: Object,
      default: function () {
        return { autumn: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } }, winter: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } }, summer: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } }, spring: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } } }
      }
    },
    seasonData: {
      type: Object,
      default: function () {
        return { autumn: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, winter: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, summer: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, spring: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } } }
      }
    }
  },
  data () {
    return {
      active: false,
      show: false,
      sizeSeasonCanvas: 140,
      ratio: window.devicePixelRatio,
      heightDiag: 120,
      widthDiag: 220
    }
  },
  computed: {
    color () {
      if (this.resultType === 'sunlight') {
        return '#F8CB15'
      } else {
        return '#008FC8'
      }
    },
    title () {
      if (this.resultType === 'sunlight') {
        return 'Ensoleillement direct'
      } else {
        return 'Luminosité naturelle'
      }
    },
    subtitle () {
      if (this.resultType === 'sunlight') {
        return 'Par jour, sous réserve de beau temps'
      } else {
        return 'Par jour, supérieure à 200 lux'
      }
    },
    hourTitle () {
      if (this.resultType === 'sunlight') {
        return 'Horaires d\'ensoleillement'
      } else {
        return 'Horaires de luminosité'
      }
    }
  },
  methods: {
    hourString: hourString,
    roomTypeString: roomTypeString,
    toggleModal () {
      const body = document.querySelector('body')
      this.active = !this.active
      this.active
        ? body.classList.add('modal-open')
        : body.classList.remove('modal-open')
      setTimeout(() => (this.show = !this.show), 10)
    },
    hh_mm_ss_to_hour (value) {
      const a = value.split(':')
      return parseInt(a[0])
    },
    defineSlots (slotHours) {
      const arraySlot = new Array(24).fill(false)
      slotHours.forEach((slot) => {
        if (slot.legend === 1) {
          const start = this.hh_mm_ss_to_hour(slot.start)
          const end = this.hh_mm_ss_to_hour(slot.end)
          for (let j = start; j < end; j++) {
            arraySlot[j] = true
          }
        }
      })

      return arraySlot
    },
    drawIndicator (canvas, ratio, color, averageValue, cityValue, maxValue, thickness) {
      const ctx = canvas.getContext('2d')
      const sizeCanvas = canvas.width
      const xCenter = sizeCanvas / 2
      const yCenter = sizeCanvas / 2

      const cityColor = '#cfd8dc'

      // On efface le canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Calcul gauge circle
      const valueParse = averageValue.split(':')
      const moyenneParse = cityValue.split(':')
      const maxParse = maxValue.split(':')
      const hoursValue = parseInt(valueParse[0] * 60) + parseInt(valueParse[1])
      const hoursMoy = parseInt(moyenneParse[0] * 60) + parseInt(moyenneParse[1])
      const hoursMax = parseInt(maxParse[0] * 60) + parseInt(maxParse[1])

      let scale = Math.max(hoursValue, hoursMax, hoursMoy)

      if (!this.showLocaleAverages) {
        scale = Math.max(hoursValue, hoursMax)
      }

      // Trace le cercle de la valeur
      const valueCircle = Math.min(hoursValue * 2 * Math.PI / scale, 2 * Math.PI)
      this.drawCircle(ctx, ratio, xCenter, yCenter, Math.min(xCenter, yCenter), thickness, color, valueCircle)

      if (this.showLocaleAverages) {
        // Trace le cercle de la ville
        const valueCircleMoy = Math.min(hoursMoy * 2 * Math.PI / scale, 2 * Math.PI)
        this.drawCircle(ctx, ratio, xCenter, yCenter, Math.min(xCenter, yCenter) - 5 / 4 * thickness * ratio, thickness, cityColor, valueCircleMoy)
      }
    },
    drawCircle (ctx, ratio, xCenter, yCenter, rayon, thickness, color, value) {
      // Paramètres
      const backgroundColor = '#F5F6FA'
      const startAngle = -0.5 * Math.PI
      const endAngle = value - 0.5 * Math.PI
      const realRayon = rayon - ratio * thickness / 2

      // Arc de cercle de fond
      ctx.beginPath()
      ctx.arc(xCenter, yCenter, rayon - ratio * thickness / 2, 0, 2 * Math.PI)
      ctx.strokeStyle = backgroundColor
      ctx.lineWidth = ratio * thickness
      ctx.stroke()
      // Arc de cercle coloré en fonction de la note
      ctx.beginPath()
      ctx.arc(xCenter, yCenter, realRayon, startAngle, endAngle)
      ctx.strokeStyle = color
      ctx.lineWidth = ratio * thickness
      ctx.stroke()
      // Tracer les extrémités
      if (value > 0) {
        ctx.beginPath()
        ctx.arc(xCenter + realRayon * Math.cos(startAngle), yCenter + realRayon * Math.sin(startAngle), ratio * thickness / 2, 0, 2 * Math.PI)
        ctx.arc(xCenter + realRayon * Math.cos(endAngle), yCenter + realRayon * Math.sin(endAngle), ratio * thickness / 2, 0, 2 * Math.PI)
        ctx.fillStyle = color
        ctx.fill()
      }
    },
    drawDiag (canvas, ratio, values, startDay, endDay, lang) {
      const ctx = canvas.getContext('2d')
      const widthCanvas = canvas.width
      const heightCanvas = canvas.height

      const thickness = 8 * ratio
      const normalColor = '#cfd8dc'
      const hourFontSize = 12 * ratio
      const seasonFontSize = 18 * ratio
      ctx.imageSmoothingEnabled = true

      // Canvas cleaning
      ctx.clearRect(0, 0, widthCanvas, heightCanvas)

      // Text
      ctx.font = '600 ' + hourFontSize.toString() + 'px VAG Rounded Next'
      ctx.fillStyle = '#000000'
      const widthText = Math.round(ctx.measureText('00h00').width)
      const rayon = Math.min(widthCanvas / 2 - thickness - widthText, heightCanvas - hourFontSize - seasonFontSize - thickness)

      // Start and end hours of the day
      const startDayHour = parseInt(startDay.split(':')[0])
      const endDayHour = parseInt(endDay.split(':')[0])

      const durationDay = endDayHour - startDayHour
      const angle = Math.PI / durationDay
      const xCircle = widthCanvas / 2
      const yCircle = heightCanvas - seasonFontSize

      for (let i = startDayHour; i < endDayHour; i++) {
        ctx.beginPath()
        ctx.arc(xCircle, yCircle, rayon, angle * (i - startDayHour) - Math.PI, angle * ((i - startDayHour) + 1) - Math.PI)
        if (values[i] === true) {
          ctx.strokeStyle = this.color
        } else {
          ctx.strokeStyle = normalColor
        }
        ctx.lineWidth = thickness
        ctx.stroke()

        ctx.lineWidth = ratio
        ctx.moveTo(xCircle + (rayon - thickness / 2) * Math.cos(angle * (i - startDayHour) - Math.PI), yCircle + (rayon - thickness / 2) * Math.sin(angle * (i - startDayHour) - Math.PI))
        ctx.lineTo(xCircle + (rayon + thickness / 2) * Math.cos(angle * (i - startDayHour) - Math.PI), yCircle + (rayon + thickness / 2) * Math.sin(angle * (i - startDayHour) - Math.PI))
        ctx.stroke()
      }

      // Watch lines
      const linesCount = 5
      const angleBetweenLines = Math.PI / (linesCount - 1)
      for (let i = 0; i < linesCount; i++) {
        ctx.beginPath()
        ctx.lineWidth = 2 * ratio
        ctx.strokeStyle = normalColor
        ctx.moveTo(xCircle + (rayon - 9 / 4 * thickness) * Math.cos(angleBetweenLines * i - Math.PI), yCircle + (rayon - 9 / 4 * thickness) * Math.sin(angleBetweenLines * i - Math.PI))
        ctx.lineTo(xCircle + (rayon - thickness) * Math.cos(angleBetweenLines * i - Math.PI), yCircle + (rayon - thickness) * Math.sin(angleBetweenLines * i - Math.PI))
        ctx.stroke()
      }

      // Hour representation
      let hourseparator = 'h'
      let morning = 'h'
      let afternoon = 'h'

      if (lang !== 'fr') {
        hourseparator = ':'
        morning = ' a.m.'
        afternoon = ' p.m.'
      }

      ctx.beginPath()
      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'
      ctx.fillStyle = '#77869E'
      ctx.font = '400 ' + hourFontSize.toString() + 'px VAG Rounded Next'
      const startDayText = startDayHour.toString() + morning
      const endDayText = endDayHour.toString() + afternoon
      ctx.fillText(startDayText, xCircle + rayon * Math.cos(-Math.PI), heightCanvas)
      ctx.fillText(endDayText, xCircle + rayon * Math.cos(0), heightCanvas)

      ctx.beginPath()
      ctx.textBaseline = 'alphabetic'
      ctx.font = '400 ' + hourFontSize.toString() + 'px VAG Rounded Next'
      ctx.fillStyle = '#000000'

      // Slots hours from the left
      let lastY = 0
      ctx.textAlign = 'right'
      for (let i = startDayHour; i <= startDayHour + (durationDay / 2); i++) {
        if (values[i - 1] !== values[i]) {
          const angleHour = angle * (i - startDayHour) - Math.PI
          const xText = xCircle + (rayon + thickness) * Math.cos(angleHour)
          const yText = yCircle + (rayon + thickness) * Math.sin(angleHour)

          if (yText < lastY - hourFontSize || lastY === 0) {
            let text = i.toString() + hourseparator + '00'
            if (i < 10) {
              text = '0' + text
            }
            ctx.fillText(text, xText, yText)
            lastY = yText
          }
        }
      }

      // Slots hours from the right
      lastY = 0
      ctx.textAlign = 'left'
      for (let i = endDayHour - 1; i > startDayHour + (durationDay / 2); i--) {
        if (values[i] !== values[i - 1]) {
          const angleHour = angle * (i - startDayHour) - Math.PI
          const xText = xCircle + (rayon + thickness) * Math.cos(angleHour)
          const yText = yCircle + (rayon + thickness) * Math.sin(angleHour)

          if (yText < lastY - hourFontSize || lastY === 0) {
            let text = i.toString() + hourseparator + '00'
            if (i < 10) {
              text = '0' + text
            }
            ctx.fillText(text, xText, yText)
            lastY = yText
          }
        }
      }
    },
    drawCanvas () {
      const seasons = ['spring', 'summer', 'autumn', 'winter']
      seasons.forEach((season) => {
        // Indicator
        let el = this.$refs[season].querySelector('canvas')
        if (el === null) {
          const canvas = document.createElement('canvas')
          canvas.setAttribute('id', 'canvas-' + season + '-indicator')
          canvas.setAttribute('width', Math.ceil(this.ratio * this.sizeSeasonCanvas) + 'px')
          canvas.setAttribute('height', Math.ceil(this.ratio * this.sizeSeasonCanvas) + 'px')
          canvas.setAttribute('style', 'width:' + Math.ceil(this.sizeSeasonCanvas) + 'px; height:' + Math.ceil(this.sizeSeasonCanvas) + 'px')
          this.$refs[season].appendChild(canvas)
          el = this.$refs[season].querySelector('canvas')
        }
        this.drawIndicator(el, this.ratio, this.color, this.roomData[season].averages[this.resultType], this.seasonData[season].averages[this.resultType], '12:00', 8)

        // Diagram
        let diag = this.$refs['slot-' + season].querySelector('canvas')
        if (diag === null) {
          const canvas = document.createElement('canvas')
          canvas.setAttribute('id', 'canvas-' + season + '-diag')
          canvas.setAttribute('width', Math.ceil(this.ratio * this.widthDiag) + 'px')
          canvas.setAttribute('height', Math.ceil(this.ratio * this.heightDiag) + 'px')
          canvas.setAttribute('style', 'width:' + Math.ceil(this.widthDiag) + 'px; height:' + Math.ceil(this.heightDiag) + 'px')
          this.$refs['slot-' + season].appendChild(canvas)
          diag = this.$refs[season].querySelector('canvas')
        }
        const values = this.defineSlots(this.roomData[season].slots[this.resultType])
        const startDay = this.seasonData[season].daytime.start
        const endDay = this.seasonData[season].daytime.end
        this.drawDiag(diag, this.ratio, values, startDay, endDay, 'fr')
      })
    },
    resizeWindow () {
      this.ratio = window.devicePixelRatio
    }
  },
  created () {
    window.addEventListener('resize', this.resizeWindow)
  },
  mounted: function () {
    const that = this

    that.resizeWindow()

    WebFont.load({
      custom: {
        families: ['VAG Rounded Next:400']
      },
      active: function () {
        that.drawCanvas()
      }
    })
  },
  unmount () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  updated: function () {
    this.drawCanvas()
  },
  watch: {
    triggerModal () {
      this.toggleModal()
    },
    resultType () {
      this.drawCanvas()
    }
  }
}
</script>

<style scoped>
  .modal-content-solen {
    border: none;
    border-radius: 10px;
    position: absolute;
    top: 0;
  }

  .modal-header-solen {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
    border-bottom: none;
  }

  .modal-body-solen {
    padding-top: 0px;
  }

  .modal-footer-solen {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .room-values {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
  }

  .certiftitle {
    font-size: 2.2em;
    line-height: 1.2em;
    font-weight: 500;
  }

  .indicator {
    height: 160px;
    margin: 15px 0px;
    position: relative;
  }

  .season-values .indicator {
    height: 140px;
  }

  .indicator .value {
    font-weight: 500;
    font-size:2.3em;
    position: absolute;
    z-index:2;
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .room-values .city {
    font-size: 1.1em;
    font-weight: 500;
  }

  .season {
    text-align: center;
    padding:10px;
    font-size:1.3em;
    color:#212529;
    margin-top: 15px;
  }

  .season-values .city {
    font-size:1em;
    line-height: 1em;
  }

  .certifsubtitle {
    text-align: left;
    font-size: 1.1em;
  }

  .season-values .indicator .value {
    font-size:1.75em;
  }

  .slots {
    border-radius: 0px;
    padding: 0px 0px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    width: 100%;
    background-color: #ffffff;
    height:unset;
  }

  .season-title {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
  }

  .circle {
    width: 1.1em;
    height: 1.1em;
    border-radius: 0.55em;
    margin:2.5px 10px;
  }

  .annual-average {
    font-size:1.1em;
    font-weight: 500;
    line-height: 1.1em;
  }

  .city {
    color: #b8c0c4;
    font-size:1em;
    line-height: 1em;
  }

  .city .circle {
    background-color: #cfd8dc;
  }

  .slots .title {
    font-size:1.2em;
    line-height: 1em;
    margin: 10px 0px;
    font-weight: 500;
  }
</style>
