import { createWebHistory, createRouter } from 'vue-router'
import TheProjectPage from '@/components/TheProjectPage'
import ThePropertyPage from '@/components/ThePropertyPage'
import Error404 from '@/components/Error404'
import Error500 from '@/components/Error500'

const routes = [
  {
    path: '/admin/appartement/:propertyUrl',
    name: 'PropertyAloneAdmin',
    component: ThePropertyPage,
    props: true
  },
  {
    path: '/admin/:projectUrl',
    name: 'PropertyListAdmin',
    component: TheProjectPage,
    props: true
  },
  {
    path: '/admin/:projectUrl/appartement-:propertyUrl',
    name: 'PropertyAdmin',
    component: ThePropertyPage,
    props: true
  },
  {
    path: '/:projectUrl',
    name: 'Projet',
    component: TheProjectPage,
    props: true
  },
  {
    path: '/:projectUrl/appartement-:propertyUrl',
    name: 'Property',
    component: ThePropertyPage,
    props: true
  },
  {
    path: '/appartement/:propertyUrl',
    name: 'PropertyAlone',
    component: ThePropertyPage,
    props: true
  },
  {
    path: '/:catchAll(.*)*',
    name: 'Error404',
    component: Error404
  },
  {
    path: '/:catchAll(.*)*',
    name: 'Error500',
    component: Error500
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line
    gtag('config', 'UA-75893770-12', {'page_path': to.path})
  }
})

export default router
