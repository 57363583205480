<template>
  <div id="property">
    <div id="sidebar">
      <div>
        <div class="developer-logo" :title="developerName" v-if="developerLogo" :style="{ backgroundImage: 'url(' + developerLogo + ')' }"></div>
        <div class="name-project">{{ projectName }}</div>
        <div class="d-flex justify-content-center caracteristiques pb-3">
          <div class="px-2 pt-1"><span class="cs-icon icon-location-full"></span></div>
          {{ projectAddress }}
        </div>
        <div v-if="projectUrl !== undefined" class="my-2">
          <router-link class="back-button" :to="{ name: 'Projet', params: { projectUrl: projectUrl }}">
            <span class="cs-icon icon-ArrowLeft"></span>Retour
          </router-link>
        </div>
        <div>
          <div class="title">Informations sur le bien</div>
          <div class="caracteristiques">{{ propTypeString(propType) }} {{ roomCountString(roomCount) }}</div>
          <div v-if="floor" class="caracteristiques">Etage : {{ floorString(floor) }}</div>
          <div v-if="surface" class="caracteristiques">Surface : {{ numberString(surface) }} m²</div>
          <div v-if="orientation" class="caracteristiques">Orientation : {{ orientationString(orientation) }}</div>
        </div>
        <div v-if="(platformOptions.show_certificate_button && certificateUrl != '') || planUrl != '' || cityscanConfig.report_url != ''">
          <div class="title">Documents</div>
          <a class="sidebar-button" v-if="platformOptions.show_certificate_button && certificateUrl != ''" @click="showCertificate  = !showCertificate" href="#"><div class="cs-icon icon-file-medium"></div>Certificat Solen</a>
          <a class="sidebar-button cs-report" v-if="platformOptions.show_cityscan && cityscanConfig.report_url != ''" @click="showCsReport  = !showCsReport" href="#"><div class="cs-icon icon-picto-cs"></div>Certificat<br />Qualité de vie</a>
          <a class="sidebar-button" v-if="planUrl != ''" @click="showPlan  = !showPlan" href="#"><div class="cs-icon icon-layer-carto"></div>Plans de vente</a>
        </div>
      </div>
      <div>
        <a class="sidebar-button" v-if="platformOptions.show_share_button" @click="shareProperty  = !shareProperty" href="#"><div class="cs-icon icon-share1"></div>Partager</a>
        <a class="sidebar-button" @click="showFaq  = !showFaq" href="#"><div class="cs-icon icon-help-medium"></div>Aide</a>
        <div class="caution">Documents et informations non contractuelles</div>
      </div>
    </div>
    <div id="content">
      <div id="topbar">
        <div class="title">{{ propTypeString(propType) }} {{ propertyName }}</div>
        <img class="solen-logo" src="../assets/img/logo_solen.svg">
      </div>
      <div class="menu">
        <div class="list-rooms">
          <div :class="{ selected: roomSelected == null && !showWidgetCityScan }" @click="changeRoom(null)">Vue d'ensemble</div>
          <div v-for="room in rooms" :key="room[0]" :class="{ selected: roomSelected == room[0] }" @click="changeRoom(room[0])">{{ roomTypeString(room[1]['room_type'], room[1]['room_nbr'])  }}</div>
          <div v-if="platformOptions.show_cityscan" @click="changeForWidget" :class="{ selected: roomSelected == null && showWidgetCityScan }">Explorer le quartier</div>
        </div>
      </div>
      <div id="data" class="d-flex">
        <div id="plan" class="solen-shadow position-relative">
          <the-property-page-canvas-gbxml :levels-data="levels"
            :rooms-data="rooms"
            :gps-coordinates="gpsCoordinates"
            :utc="utc"
            :hour-selected="hourSelected"
            :season-selected="seasonSelected"
            :level-selected="levelSelected"
            :room-selected="roomSelected"
            :show-lights="platformOptions.show_lights"
            :show-natural-light="!platformOptions.desactivate_natural_light"
            @change-room="changeRoom"
            v-if="roomSelected !== null && gbxml && !platformOptions.desactivate_natural_light && !showWidgetCityScan"
            ></the-property-page-canvas-gbxml>
          <the-property-page-cs-widget :cs-id="cityscanConfig.id" v-else-if="roomSelected === null && showWidgetCityScan">
          </the-property-page-cs-widget>
          <the-property-page-canvas-image :levels-data="levels"
            :rooms-data="rooms"
            :gps-coordinates="gpsCoordinates"
            :utc="utc"
            :hour-selected="hourSelected"
            :season-selected="seasonSelected"
            :level-selected="levelSelected"
            :room-selected="roomSelected"
            :show-lights="platformOptions.show_lights"
            :show-natural-light="!platformOptions.desactivate_natural_light"
            @change-room="changeRoom"
            v-else
            ></the-property-page-canvas-image>
          <div id="title-canvas" v-if="roomSelected !== null">
            <span v-if="platformOptions.desactivate_natural_light === false">Luminosité de la pièce</span>
            <span v-else>Ensoleillement de la pièce</span>
          </div>
          <div id="legend" v-if="roomSelected !== null && platformOptions.desactivate_natural_light === false">
            <the-property-page-legend :gbxml="gbxml"></the-property-page-legend>
          </div>
          <div id="comparator" v-if="roomSelected !== null && platformOptions.comparator_option !== 'deactivate' && platformOptions.desactivate_natural_light === false">
            <the-property-page-comparator @change-comparator-status="changeComparatorStatus" :comparatorStatus="showComparator" :hour-selected="hourSelected" :season-selected="seasonSelected" :room-selected="roomSelected" :rooms-data="rooms"></the-property-page-comparator>
          </div>
          <div id="levels" v-if="Object.keys(levels).length > 1 && roomSelected  == null && !showWidgetCityScan">
            <div v-for="level in Object.keys(levels).sort()" :key="level" @click="levelSelected = level" :class="{ 'level-selected': level === levelSelected }">
              <div>{{ levelString(level) }}</div>
            </div>
          </div>
          <div id="compass" v-if="!showWidgetCityScan">
            <the-property-page-compass :orientation="orientationCompass"></the-property-page-compass>
          </div>
        </div>
        <div id="rightbar" v-if="roomSelected !== null || !showWidgetCityScan">
          <the-property-page-slider-season class="solen-shadow"
            :season-selected="seasonSelected"
            :hour-selected="hourSelected"
            :default-hour="defaultHour"
            :season-data="locationSeasonData[seasonSelected]"
            @change-season="changeSeason"
            @change-hour="changeHour"></the-property-page-slider-season>
          <the-property-page-strong-point class="solen-shadow"
            :season-selected="seasonSelected"
            :season-data="locationSeasonData"
            :rooms-data="rooms"
            :show-natural-light="!platformOptions.desactivate_natural_light"
            :show-locale-averages="platformOptions.show_city_averages"
            :roomSelected="roomSelected"></the-property-page-strong-point>
          <the-property-page-cs-indicator v-if="platformOptions.show_cityscan && roomSelected == null && cityscanConfig.indicators.length > 0" class="solen-shadow"
            :indicators="cityscanConfig.indicators"></the-property-page-cs-indicator>
          <the-property-page-room-diagram class="solen-shadow" v-if="roomSelected != null"
            :hour-selected="hourSelected"
            :default-hour="defaultHour"
            :show-natural-light="!platformOptions.desactivate_natural_light"
            :season-data="locationSeasonData[seasonSelected]"
            :slots="slots"></the-property-page-room-diagram>
        </div>
      </div>
    </div>
  </div>
  <the-faq :trigger-modal="showFaq"></the-faq>
  <the-share-property-modal v-if="platformOptions.show_share_button" :trigger-modal="shareProperty" :property-url="propertyUrl" :project-url="projectUrl" :property-name="propertyName" :project-name="projectName"></the-share-property-modal>
  <the-pdf-viewer v-if="platformOptions.show_certificate_button && certificateUrl != ''" :trigger-modal="showCertificate" :pdf-url="certificateUrl" :title="'Certificat Solen'"></the-pdf-viewer>
  <the-pdf-viewer v-if="platformOptions.show_cityscan && cityscanConfig.report_url != ''" :trigger-modal="showCsReport" :pdf-url="cityscanConfig.report_url" :title="'Certificat Qualité de Vie'"></the-pdf-viewer>
  <the-pdf-viewer  v-if="planUrl != ''" :trigger-modal="showPlan" :pdf-url="planUrl" :title="'Plans de vente'"></the-pdf-viewer>
</template>

<script>
import axios from 'axios'
import { roomCountString, propTypeString, floorString, orientationString, numberString, roomTypeString, levelString } from '../util/filters.js'
import ThePropertyPageCompass from './ThePropertyPageCompass.vue'
import ThePropertyPageSliderSeason from './ThePropertyPageSliderSeason.vue'
import ThePropertyPageStrongPoint from './ThePropertyPageStrongPoint.vue'
import ThePropertyPageComparator from './ThePropertyPageComparator.vue'
import ThePropertyPageLegend from './ThePropertyPageLegend.vue'
import TheFaq from './TheFaq.vue'
import ThePdfViewer from './ThePdfViewer.vue'
import ThePropertyPageRoomDiagram from './ThePropertyPageRoomDiagram.vue'
import ThePropertyPageCanvasImage from './ThePropertyPageCanvasImage.vue'
import ThePropertyPageCanvasGbxml from './ThePropertyPageCanvasGbxml.vue'
import TheSharePropertyModal from './TheSharePropertyModal.vue'
import ThePropertyPageCsWidget from './ThePropertyPageCsWidget.vue'
import ThePropertyPageCsIndicator from './ThePropertyPageCsIndicator.vue'

export default {
  name: 'the-property-page',
  props: {
    projectUrl: String,
    propertyUrl: String
  },
  components: {
    ThePropertyPageCompass,
    ThePropertyPageSliderSeason,
    ThePropertyPageStrongPoint,
    ThePropertyPageComparator,
    ThePropertyPageLegend,
    TheFaq,
    ThePdfViewer,
    ThePropertyPageCsWidget,
    ThePropertyPageCsIndicator,
    ThePropertyPageRoomDiagram,
    ThePropertyPageCanvasImage,
    ThePropertyPageCanvasGbxml,
    TheSharePropertyModal
  },
  data () {
    return {
      propertyName: '',
      propType: 0,
      roomCount: 0,
      floor: null,
      surface: null,
      orientation: null,
      gbxml: false,

      projectName: '',
      projectAddress: '',
      certificateUrl: '',
      planUrl: '',
      gpsCoordinates: {
        latitude: 0,
        longitude: 0
      },
      utc: 1,
      locationSeasonData: { autumn: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, winter: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, summer: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, spring: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } } },
      developerName: '',
      developerLogo: '',
      rooms: new Map(),
      levels: { 0: { background_img: '', layer_img: '', orientation: 0 } },

      platformOptions: { show_city_averages: true, show_lights: true, show_certificate_button: true, show_share_button: false, comparator_option: 'activate-close', desactivate_natural_light: false, show_cityscan: false },

      cityscanConfig: { id: null, report_url: '', indicators: [] },

      hourSelected: 13,
      roomSelected: null,
      levelSelected: '0',
      defaultHour: 13,
      seasonSelected: 'summer',

      showFaq: false,
      showCertificate: false,
      showCsReport: false,
      showPlan: false,
      showComparator: false,
      shareProperty: false,
      showWidgetCityScan: false
    }
  },
  computed: {
    orientationCompass () {
      if (this.levels !== {} && this.levelSelected !== null && this.levelSelected in this.levels) {
        if (this.roomSelected !== null && this.gbxml && !this.platformOptions.desactivate_natural_light) {
          return this.levels[this.levelSelected].gbxml.orientation
        } else {
          return this.levels[this.levelSelected].orientation
        }
      } else {
        return 0
      }
    },
    slots () {
      if (this.roomSelected !== null && this.rooms.size > 0) {
        return this.rooms.get(this.roomSelected).seasons_data[this.seasonSelected].slots
      } else {
        return { natural_light: [], sunlight: [] }
      }
    },
    startDay () {
      const startHour = this.locationSeasonData[this.seasonSelected].daytime.start
      return parseInt(startHour.substring(0, 2))
    },
    endDay () {
      const endHour = this.locationSeasonData[this.seasonSelected].daytime.end
      return parseInt(endHour.substring(0, 2))
    }
  },
  methods: {
    roomCountString: roomCountString,
    propTypeString: propTypeString,
    floorString: floorString,
    orientationString: orientationString,
    numberString: numberString,
    roomTypeString: roomTypeString,
    levelString: levelString,
    /**
      * @description  fonction venant de ThePropertyPageSliderSeasons pour hourSelected
      * @param        value Number heure
    */
    changeHour (value) {
      if (value === undefined) {
        value = 13
      }
      this.hourSelected = value
    },
    /**
      * @description  fonction venant de ThePropertyPageSliderSeasons, se déclenche au clique sur saison dans slider, réinitialise hourSelected à 14, nouvelle saison pour rechercher les nouvelles infos
      * @param        value String saison
    */
    changeSeason (value) {
      if (value === undefined) {
        value = 'summer'
      }
      this.seasonSelected = value

      // On change l'heure selectionnée si celle-ci est en dehors du créneau de la journée de la nouvelle saison
      if (this.hourSelected < this.startDay) {
        this.hourSelected = this.startDay
      } else if (this.hourSelected > this.endDay) {
        this.hourSelected = this.endDay
      }
    },
    changeRoom (value) {
      if (value === undefined || value === null) {
        value = null
      } else {
        this.levelSelected = this.rooms.get(value).room_level.toString()
      }
      this.roomSelected = value
      this.showWidgetCityScan = false
    },
    changeForWidget () {
      this.roomSelected = null
      this.showWidgetCityScan = true
    },
    changeComparatorStatus (value) {
      if (value === undefined) {
        value = false
      }
      this.showComparator = value
    },
    async getPropertyApi () {
      await axios.get('/api/v3/properties/' + this.propertyUrl + '/')
        .then((response) => {
          const res = response.data

          // Données du bien
          this.propertyName = res.name
          this.propType = res.building_type
          this.roomCount = res.room_count
          this.orientation = res.orientation

          // Données du projet
          this.projectName = res.project.name
          this.projectAddress = res.project.address
          this.certificateUrl = res.certificate
          this.planUrl = res.plan
          this.floor = res.floor
          this.surface = res.surface
          this.developerLogo = res.developer.logo
          this.developerName = res.developer.name
          this.gpsCoordinates.latitude = parseFloat(res.location_data.latitude)
          this.gpsCoordinates.longitude = parseFloat(res.location_data.longitude)
          this.utc = res.location_data.utc
          this.locationSeasonData = res.location_data.seasons_data
          this.gbxml = res.gbxml

          // Options de la plateforme
          this.platformOptions = res.platform_options
          if (localStorage.showComparator) {
            this.showComparator = (localStorage.showComparator === 'true')
          } else {
            if (res.platform_options.comparator_option === 'activate-open') {
              this.showComparator = true
            } else {
              this.showComparator = false
            }
          }

          if (Object.keys(res.cityscan).length === 0) {
            this.platformOptions.show_cityscan = false
          } else {
            this.platformOptions.show_cityscan = true
            this.cityscanConfig = res.cityscan
          }

          if (res.platform_options.desactivate_natural_light) {
            this.platformOptions.show_lights = false
          }

          // Données de géométrie
          this.levels = res.levels_plan
          this.levelSelected = '0'

          // Données des pièces
          for (var room in res.rooms_data) {
            this.rooms.set(room, res.rooms_data[room])
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            let url = this.projectUrl
            if (url === undefined) {
              url = '404'
            }
            this.$router.push({ name: 'Error404', params: { catchAll: url } })
          } else {
            let paramsUrl = {}
            if (this.projectUrl !== undefined && this.propertyUrl !== undefined) {
              paramsUrl = { catchAll: [this.projectUrl, 'appartement-' + this.propertyUrl] }
            } else if (this.propertyUrl !== undefined) {
              paramsUrl = { catchAll: 'appartement-' + this.propertyUrl }
            } else {
              paramsUrl = { catchAll: '500' }
            }
            this.$router.push({ name: 'Error500', params: paramsUrl })
          }
        })
    }
  },
  watch: {
    propertyName () {
      let title = process.env.VUE_APP_TITLE

      if (this.projectName !== '') {
        title = propTypeString(this.propType) + ' ' + this.propertyName + ' - ' + this.projectName + ' - ' + title
      }

      document.title = title
    },
    showComparator (newValue) {
      localStorage.showComparator = newValue
    }
  },
  mounted () {
    this.getPropertyApi()
  }
}
</script>

<style scoped>
  #property {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    height: -webkit-fill-available;
    min-height: 600px;
    min-width: 1280px;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
  }

  #property > * {
    flex-shrink: 0;
    min-height: -webkit-fill-available;
  }

  #sidebar {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-right: 1px solid #E5E6EB;
    width: 240px;
    justify-content: space-between;
    padding: 0rem 1rem;
  }

  #content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 16px;
  }

  .caution {
    font-size: 0.7em;
    font-style: italic;
    padding-top: 0.5em;
    padding-bottom: 0.25em;
  }

  .sidebar-button {
    min-height: 32px;
    padding: 0 12px 0 26px;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 5px;
    color: #1C62B4;
  }

  .sidebar-button:hover {
    background-color: #E5E6EB;
    color: #2071ce;
  }

  .sidebar-button > .cs-icon {
    margin-right: 18px;
  }

  .sidebar-button > .cs-icon {
    margin-right: 18px;
    font-size: 18px;
  }

  .cs-report {
    line-height: 1.1em;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .developer-logo {
    height: 48px;
    width: 100%;
    margin: 14px 0px;
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
  }

  #sidebar .title {
    padding-top: 16px;
    padding-bottom: 4px;
    font-size: 1.2em;
    font-weight: 500;
  }

  .caracteristiques {
    line-height: 1.3em;
    font-size: 0.9em;
  }

  .icon-location-full {
    font-size: 1.3em;
  }

  .name-project {
    font-size: 1.6em;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  .back-button {
    background-color: #1C62B4;
    border-radius: 20px;
    color: white;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: inline-flex;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 15px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .back-button > .cs-icon {
    padding-right: 10px;
  }

  .back-button:hover {
    background-color: #2071ce;
  }

  #topbar {
    border-bottom: 1px solid #E5E6EB;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #topbar .title {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    padding: 22px 0px;
  }

  .solen-logo {
    height: 36px;
  }

  #content > .menu {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 18px;
    padding-top: 12px;
    padding-bottom: 0px;
  }

  .list-rooms {
    display: flex;
  }

  .list-rooms > .selected {
    opacity: 1;
    border-bottom: solid 2px #1C62B4;
  }

  .list-rooms > div {
    color: #2C3E50;
    opacity: 0.7;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 8px;
    padding-bottom: 2px;
    margin: 0px 8px;
    line-height: 28px;
  }

  .list-rooms > div:hover {
    cursor: pointer;
    opacity: 1;
    background-color: #E5E6EB;
    border-radius: 5px;
  }

  .list-rooms > .selected:hover {
    background-color: unset;
    border-radius: 0px;
  }

  .settings {
    cursor: pointer;
    width: 36px;
  }

  .settings:hover {
    border-radius: 20px;
    color: black;
    background-color: #E5E6EB;
  }

  #plan {
    background-color: white;
    border-radius: 10px;
    height: 100%;
    flex-grow: 1;
    padding: 0px;
    overflow: hidden;
  }

  #rightbar {
    margin-left: 16px;
    width: 380px;
    display: flex;
    flex-direction: column;
  }

  #strong-point {
    margin-top: 18px;
    background-color: white;
    border-radius: 10px;
    flex-grow: 1;
  }

  #data {
    height: 100%;
    padding-top: 14px;
    padding-right: 24px;
    padding-left: 24px;
  }

  #comparator {
    position: absolute;
    right: 9px;
    top: 7px;
    z-index: 99;
  }

  #legend {
    position: absolute;
    left: 18px;
    bottom: 14px;
    z-index: 99;
  }

  #title-canvas {
    position: absolute;
    left: 18px;
    top: 14px;
    z-index: 99;
    font-size: 17px;
    line-height: 17px;
  }

  #compass {
    position: absolute;
    right: 14px;
    bottom: 14px;
    z-index: 99;
    width: 120px;
    height: 120px;
  }

  #levels {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 99;
    display:flex;
    flex-direction: column-reverse;
    overflow-y: auto;
  }

  #levels > div {
    width: 60px;
    height: 42px;
    border-top: solid 1px #2B6CB9;
    border-right: solid 1px #2B6CB9;
    border-left: solid 1px #2B6CB9;
    background-color: white;
    color: #2B6CB9;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #levels > div:first-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: solid 1px #2B6CB9;
  }

  #levels > div:last-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  #levels > div:hover {
    background-color: #E5E6EB;
  }

  .level-selected {
    background-color: #2B6CB9 !important;
    color: white !important;
  }
</style>
