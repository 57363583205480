<template>
  <div class="modal fade" :class="{show, 'd-block': active}" id="faqModal" aria-hidden="true" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content modal-content-solen solen-shadow ">
        <div class="modal-header modal-header-solen">
          <h5 class="modal-title" id="faqModalLabel">Foire aux questions</h5>
          <button type="button" class="btn-close" @click="toggleModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            Pour toute autre question, vous pouvez nous contacter par téléphone au <a href="tel:+33176361390">+33 1 76 36 13 90</a> ou via notre <a href="https://www.solen.co/contact" target="_blank">formulaire de contact</a>.
          </div>
          <div v-if="charged" class="accordion accordion-flush" id="questions">
            <div v-for="(element, key) in faq" :key="key" class="accordion-item">
              <h2 class="accordion-header" :id="'heading' + key">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + key" aria-controls="collapseOne">
                  {{ element.question }}
                </button>
              </h2>
              <div :id="'collapse' + key" class="accordion-collapse collapse" :aria-labelledby="'heading' + key" data-bs-parent="#questions">
                <div class="accordion-body" v-html="element.answer">
                </div>
              </div>
            </div>
          </div>
          <div v-else class="alert alert-warning" role="alert">
            Il y a eu une erreur lors du chargement de la foire au question, vous pouvez nous contacter direcement par téléphone.
          </div>
        </div>
        <div class="modal-footer modal-footer-solen">
          <div class="solen-button" @click="toggleModal">J'ai compris</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="active" class="modal-backdrop fade show"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'the-faq',
  props: {
    triggerModal: Boolean
  },
  data () {
    return {
      active: false,
      show: false,
      charged: true,
      faq: {}
    }
  },
  methods: {
    toggleModal () {
      const body = document.querySelector('body')
      this.active = !this.active
      this.active
        ? body.classList.add('modal-open')
        : body.classList.remove('modal-open')
      setTimeout(() => (this.show = !this.show), 10)
    },
    async getFaqApi () {
      await axios.get('/api/v3/faq/')
        .then((response) => {
          this.faq = response.data.faq
        })
        .catch(() => {
          this.charged = false
        })
    }
  },
  watch: {
    triggerModal () {
      this.toggleModal()
    }
  },
  mounted () {
    this.getFaqApi()
  }
}
</script>

<style scoped>
  .modal-content-solen {
    border: none;
    border-radius: 10px;
  }

  .modal-header-solen {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .modal-footer-solen {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .solen-button {
    background-color: #1C62B4;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .solen-button:hover {
    background-color: #2071ce;
  }

  .accordion-button {
    font-weight: 500;
    font-size: 20px;
    white-space: pre-line;
  }

  .modal-title {
    font-size: 28px;
  }
</style>
