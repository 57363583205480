<template>
  <div id="house" ref="house" :class="{ pointer: roomOver && roomOver !== roomSelected }" @click="clickOnRoom" @mousemove="updatePositionCursor">
    <div class="divOverlay" v-show="isLoaded" style="z-index:5">
      <canvas ref="canvasSunAndLights" :width="Math.ceil(ratio * sizeCanvas)" :height="Math.ceil(ratio * sizeCanvas)" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }"></canvas>
    </div>
    <div class="divOverlay animation" v-show="isLoaded" style="z-index:4">
      <canvas ref="canvasCircles" :width="Math.ceil(ratio * sizeCanvas)" :height="Math.ceil(ratio * sizeCanvas)" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }"></canvas>
    </div>
    <div class="divOverlay" v-show="isLoaded" style="z-index:3">
      <template v-for="(levelData, level) in levelsData" :key="level">
        <img v-show="level === levelSelected" class="img" @load="onImgLoad" alt="layer_pic" :src="levelData.layer_img" :width="Math.ceil(ratio * sizeCanvas)" :height="Math.ceil(ratio * sizeCanvas)" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }">
      </template>
    </div>
    <div class="divOverlay" v-show="isLoaded" style="z-index:2">
      <canvas ref="canvasRoom" :width="Math.ceil(ratio * sizeCanvas)" :height="Math.ceil(ratio * sizeCanvas)" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }"></canvas>
    </div>
    <div id="imgBackground" v-show="isLoaded" style="z-index:1">
      <template v-for="(levelData, level) in levelsData" :key="level">
        <img v-show="level === levelSelected" class="img"  alt="background_pic" :src="levelData.background_img" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }">
      </template>
    </div>
    <div v-if="!isLoaded" class="spinnerOverlay spinner-border text-primary" role="status">
      <span class="visually-hidden">Chargement en cours...</span>
    </div>
  </div>
</template>

<script>
import SunCalc from 'suncalc'
import { roomTypeString } from '../util/filters.js'

const lightOn = new Image()
const sunPicture = new Image()

export default {
  name: 'the-property-page-canvas-image',
  props: {
    levelsData: {
      type: Object,
      default: function () {
        return { 0: { background_img: '', layer_img: '', orientation: 0 } }
      }
    },
    gpsCoordinates: {
      type: Object,
      default: function () {
        return { latitude: 0, longitude: 0 }
      }
    },
    showNaturalLight: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    utc: Number,
    showLights: Boolean,
    hourSelected: Number,
    seasonSelected: String,
    levelSelected: String,
    roomSelected: String,
    roomsData: {
      type: Map,
      default: function () {
        return new Map()
      }
    }
  },
  data () {
    return {
      isLoaded: false,
      sizeImg: 1600,
      luxLightOn: 200,
      sizeCanvas: 400,
      sizeSunWithoutRatio: 60,
      sizeCircle: 25,
      ratio: window.devicePixelRatio,
      cursor: [0, 0],
      roomOver: null,
      circleTransparency: 0.5
    }
  },
  computed: {
    orientationRad: function () {
      return this.levelsData[this.levelSelected].orientation * Math.PI / 180
    },
    rayon: function () {
      return Math.ceil(this.ratio * (this.sizeCanvas - this.sizeSunWithoutRatio) / 2)
    },
    xCentre: function () {
      return Math.ceil(this.ratio * this.sizeCanvas / 2)
    },
    yCentre: function () {
      return Math.ceil(this.ratio * this.sizeCanvas / 2)
    },
    sizeSun: function () {
      return this.sizeSunWithoutRatio * this.ratio
    },
    dateSelected: function () {
      const month = { spring: 4, summer: 7, autumn: 10, winter: 1 }
      const actualYear = new Date()
      const year = actualYear.getFullYear()

      return new Date(Date.UTC(year, month[this.seasonSelected], 15, parseInt(this.hourSelected) - this.utc))
    },
    roomBarycentres: function () {
      const barycentres = {}
      for (const room of this.roomsData.entries()) {
        if (room[1].room_level.toString() === this.levelSelected) {
          const geometry = room[1].geometry
          barycentres[room[0]] = this.barycentre(geometry)
        }
      }
      return barycentres
    },
    luxValue: function () {
      if (this.roomSelected !== null) {
        let hourString = this.hourSelected.toString()
        if (hourString.length < 2) {
          hourString = '0' + hourString
        }
        hourString = hourString + ':00:00'

        return this.roomsData.get(this.roomSelected).seasons_data[this.seasonSelected].lux[hourString]
      } else {
        return 0
      }
    },
    isSunshine: function () {
      let sunshine = false

      if (this.roomSelected !== null) {
        const slots = this.roomsData.get(this.roomSelected).seasons_data[this.seasonSelected].slots.sunlight
        slots.forEach(slot => {
          const beginHour = this.hourToInt(slot.start)
          const endHour = this.hourToInt(slot.end)
          if (this.hourSelected >= beginHour && this.hourSelected < endHour && slot.legend === 1) {
            sunshine = true
          }
        })
      }
      return sunshine
    }
  },
  methods: {
    roomTypeString: roomTypeString,
    /**
      * @description  met à jour la position du curseur de la souris
    */
    updatePositionCursor (event) {
      const elem = this.$refs.canvasCircles
      const rect = elem.getBoundingClientRect()
      this.cursor = [(event.clientX - rect.left) * this.ratio, (event.clientY - rect.top) * this.ratio]
    },
    onImgLoad () {
      this.isLoaded = true
    },
    getModuloCircle (angle) {
      if (angle >= 2 * Math.PI) {
        angle -= 2 * Math.PI
      }
      return angle
    },
    /**
      * @description  dessine image avec param degrees pour rotation image
      * @param        ctx context du canvas
      * @param        image image a dessiner sur canvas
      * @param        x,y coordonnées dessin canvas
      * @param        w,h taille image
      * @param        degrees Number, angle pour dessin image
    */
    drawImageRotate (ctx, image, x, y, w, h, degrees) {
      ctx.save()
      ctx.translate(x + w / 2, y + h / 2)
      ctx.rotate(degrees * Math.PI / 180.0)
      ctx.translate(-x - w / 2, -y - h / 2)
      ctx.drawImage(image, x, y, w, h)
      ctx.restore()
    },
    /**
      * @description  dessine soleil/lune autour du cercle du canvas
      * @param        context context du canvas('2d')
      * @param        xCentre, yCentre coordonnées centre du canvas
      * @param        rayon rayon du cercle
      * @param        orientationRad orientationRad pour dessin soleil/lune
      * @param        datetime date pour récupération levé/couché de soleil/lune
      * @param        gpsCoordinates, longitude pour calcul phases levé/couché soleil/lune
      * @requires     calcSunPhases()
      * @requires     SunCalc.getPosition()
    */
    drawSun (context) {
      const phases = this.calcSunPhases(this.dateSelected, this.gpsCoordinates.latitude, this.gpsCoordinates.longitude)
      const sunrisePos = phases[0]
      const sunsetPos = phases[1]
      const sunPos = SunCalc.getPosition(this.dateSelected, this.gpsCoordinates.latitude, this.gpsCoordinates.longitude)
      const angleSun = this.getModuloCircle(sunPos.azimuth + this.orientationRad + Math.PI / 2)
      const xImage = this.xCentre + this.rayon * Math.cos(angleSun) - this.sizeSun / 2
      const yImage = this.yCentre + this.rayon * Math.sin(angleSun) - this.sizeSun / 2
      if ((sunsetPos >= sunrisePos && angleSun >= sunrisePos && angleSun <= sunsetPos) || (sunsetPos <= sunrisePos && ((angleSun >= sunrisePos && angleSun <= 2 * Math.PI) || (angleSun >= 0 && angleSun <= sunsetPos)))) {
        context.drawImage(sunPicture, xImage, yImage, this.sizeSun, this.sizeSun)
      }
    },
    /**
      * @description  fonction de récupération donnée levé/couché soleil/lune
      * @param        date date pour calcul phases levé/couché soleil/lune
      * @param        latitude, longitude pour calcul phases levé/couché soleil/lune
      * @requires     SunCalc.getTimes()
      * @requires     SunCalc.getPosition()
      * @returns      angleSunrise levé du soleil
      * @returns      angleSunset couché du soleil
    */
    calcSunPhases (date, latitude, longitude) {
      const times = SunCalc.getTimes(date, latitude, longitude)
      const sunrisePos = SunCalc.getPosition(times.sunrise, latitude, longitude)
      const sunsetPos = SunCalc.getPosition(times.sunset, latitude, longitude)
      const angleSunrise = this.getModuloCircle(sunrisePos.azimuth + this.orientationRad + Math.PI / 2)
      const angleSunset = this.getModuloCircle(sunsetPos.azimuth + this.orientationRad + Math.PI / 2)
      return [angleSunrise, angleSunset]
    },
    clickOnRoom (event) {
      if (this.roomOver !== null) {
        const roomSelected = this.roomOver
        this.$emit('change-room', roomSelected)
      }
    },
    /**
      * @description  réduit échelle
      * @param        coord tableau de coordonée a réduire a l'echelle
      * @param        type
    */
    scaled (coord, type) {
      const width = Math.ceil(this.$refs.canvasRoom.width)
      const height = Math.ceil(this.$refs.canvasRoom.height)
      let val
      if (type === 0) {
        val = (width * coord) / this.sizeImg
      } else {
        val = (height * coord) / this.sizeImg
      }
      return val
    },
    /**
      * @description  calcul barycentre pour placement ampoules sur canvas
      * @param        points coordonnées pièce pour calcul barycentre
    */
    barycentre (points) {
      let x = 0
      let y = 0
      let aire = 0
      for (let i = 0; i < points.length; i++) {
        let ip1 = i + 1
        if (i === points.length - 1) {
          ip1 = 0
        }
        aire += ((points[i][0] * points[ip1][1]) - (points[i][1] * points[ip1][0]))
      }
      aire = Math.abs(aire)
      for (let i = 0; i < points.length; i++) {
        let ip1 = i + 1

        if (i === points.length - 1) {
          ip1 = 0
        }
        x += (points[i][0] + points[ip1][0]) * (points[i][0] * points[ip1][1] - points[i][1] * points[ip1][0])
        y += (points[i][1] + points[ip1][1]) * (points[i][0] * points[ip1][1] - points[i][1] * points[ip1][0])
      }
      x = x / (3 * aire)
      y = y / (3 * aire)
      return [Math.abs(x), Math.abs(y)]
    },
    /**
      * @description  dessine ampoules sur canvas
      * @requires     barycentre()
    */
    drawLights (context) {
      if (this.roomsData.size > 0 && this.roomSelected !== null) {
        if (this.roomsData.get(this.roomSelected).show_bulb) {
          const barycentre = this.roomBarycentres[this.roomSelected]
          if (this.luxValue < this.luxLightOn) {
            context.drawImage(lightOn, this.scaled(barycentre[0], 0) - 16 * this.ratio, this.scaled(barycentre[1], 1) - 16 * this.ratio, 40 * this.ratio, 40 * this.ratio)
          }
        }
      }
    },
    /**
      * @description dessine le sol de la pièce sélectionnée
     */
    drawRoomSelected (ctx) {
      if (this.roomsData.size > 0 && this.roomSelected !== null) {
        const geometry = this.roomsData.get(this.roomSelected).geometry
        ctx.beginPath()
        ctx.moveTo(this.scaled(geometry[0][0], 0), this.scaled(geometry[0][1], 1))
        const transparency = 0.6
        for (let j = 1; j < geometry.length; j++) {
          ctx.lineTo(this.scaled(geometry[j][0], 0), this.scaled(geometry[j][1], 1))
        }
        if (this.showNaturalLight) {
          if (this.luxValue <= 200) {
            ctx.fillStyle = 'rgba(0, 20, 40,' + transparency * (200 - this.luxValue) / 200 + ')'
            ctx.fill()
            ctx.fillStyle = 'rgba(0, 143, 232,' + transparency * this.luxValue / 200 + ')'
            ctx.fill()
          } else if (this.luxValue > 200 && this.luxValue < 800) {
            ctx.fillStyle = 'rgba(0, 143, 232,' + transparency * (1 - (this.luxValue - 200) / (800 - this.luxValue)) + ')'
            ctx.fill()
            ctx.fillStyle = 'rgba(187, 222, 251,' + transparency * this.luxValue / 800 + ')'
            ctx.fill()
          } else if (this.luxValue >= 800) {
            ctx.fillStyle = 'rgba(187, 222, 251,' + transparency + ')'
            ctx.fill()
          }
        } else if (this.isSunshine) {
          ctx.fillStyle = 'rgba(248, 203, 21, 0.4)'
          ctx.fill()
        }
      }
    },
    /**
      * @description affiche le nom de la pièce qu'on survole
     */
    drawRoomName (ctx) {
      if (this.roomOver !== null && this.roomOver !== this.roomSelected) {
        const barycentre = this.roomBarycentres[this.roomOver]

        ctx.beginPath()
        if (this.showNaturalLight) {
          ctx.fillStyle = 'rgba(43, 108, 185, ' + this.circleTransparency + ')'
        } else {
          ctx.fillStyle = 'rgba(248, 203, 21, ' + this.circleTransparency + ')'
        }
        ctx.arc(this.scaled(barycentre[0]), this.scaled(barycentre[1]), this.sizeCircle * this.ratio, 0, 2 * Math.PI)
        ctx.fill()
        ctx.beginPath()
        ctx.arc(this.scaled(barycentre[0]), this.scaled(barycentre[1]), (this.sizeCircle * 3 / 5) * this.ratio, 0, 2 * Math.PI)
        ctx.fill()

        const baseX = this.scaled(barycentre[0])
        const baseY = this.scaled(barycentre[1]) - (this.sizeCircle + 1) * this.ratio

        const sizeText = 14 * this.ratio
        ctx.font = '400 ' + sizeText.toString() + 'px VAG Rounded Next'
        ctx.fillStyle = '#ffffff'
        const roomString = this.roomTypeString(this.roomsData.get(this.roomOver).room_type, this.roomsData.get(this.roomOver).room_nbr)
        const rectWidth = Math.round(ctx.measureText(roomString).width) + 20 * this.ratio
        const rectHeight = sizeText + 20 * this.ratio
        const arrowHeight = 10 * this.ratio
        ctx.lineWidth = 1 * this.ratio
        ctx.strokeStyle = '#2c3e50'

        this.roundRect(ctx, baseX - rectWidth / 2, baseY - rectHeight - arrowHeight, rectWidth, rectHeight, 10, true, true)
        ctx.moveTo(baseX, baseY)
        ctx.lineTo(baseX - arrowHeight / 2, baseY - arrowHeight)
        ctx.lineTo(baseX + arrowHeight / 2, baseY - arrowHeight)
        ctx.lineTo(baseX, baseY)
        ctx.stroke()
        ctx.fillStyle = '#ffffff'
        ctx.fill()
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillStyle = '#2c3e50'
        ctx.fillText(roomString, baseX, baseY - (rectHeight / 2) - arrowHeight)
      }
    },
    /**
      * @description dessine les ronds des pièces
     */
    drawCircles (ctx) {
      if (this.roomsData.size > 0) {
        for (const roomId in this.roomBarycentres) {
          if (this.roomSelected !== roomId && this.roomOver !== roomId) {
            const barycentre = this.roomBarycentres[roomId]
            ctx.beginPath()
            if (this.showNaturalLight) {
              ctx.fillStyle = 'rgba(43, 108, 185, ' + this.circleTransparency + ')'
            } else {
              ctx.fillStyle = 'rgba(248, 203, 21, ' + this.circleTransparency + ')'
            }
            ctx.arc(this.scaled(barycentre[0]), this.scaled(barycentre[1]), this.sizeCircle * this.ratio, 0, 2 * Math.PI)
            ctx.fill()
            ctx.beginPath()
            ctx.arc(this.scaled(barycentre[0]), this.scaled(barycentre[1]), (this.sizeCircle * 3 / 5) * this.ratio, 0, 2 * Math.PI)
            ctx.fill()
          }
        }
      }
    },
    roundRect (ctx, x, y, width, height, radius, fill, stroke) {
      if (typeof stroke === 'undefined') {
        stroke = true
      }
      if (typeof radius === 'undefined') {
        radius = 5
      }
      ctx.beginPath()
      ctx.moveTo(x + radius, y)
      ctx.lineTo(x + width - radius, y)
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
      ctx.lineTo(x + width, y + height - radius)
      ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
      ctx.lineTo(x + radius, y + height)
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
      ctx.lineTo(x, y + radius)
      ctx.quadraticCurveTo(x, y, x + radius, y)
      ctx.closePath()
      if (stroke) {
        ctx.stroke()
      }
      if (fill) {
        ctx.fill()
      }
    },
    /**
      * @description  dessine toutes les élements du canvasRoom
    */
    drawRooms () {
      const c1 = this.$refs.canvasRoom
      const ctxRoom = c1.getContext('2d')
      ctxRoom.clearRect(0, 0, c1.width, c1.height)

      const c2 = this.$refs.canvasCircles
      const ctxCircle = c2.getContext('2d')
      ctxCircle.clearRect(0, 0, c2.width, c2.height)

      this.drawCircles(ctxCircle)
      this.drawRoomSelected(ctxRoom)
    },
    /**
      * @description  dessine toutes les éléments du canvasSunAndLights
    */
    drawSunAndLights () {
      const c = this.$refs.canvasSunAndLights
      const ctx = c.getContext('2d')
      ctx.clearRect(0, 0, c.width, c.height)

      // Soleil
      this.drawSun(ctx)
      // Ampoules
      if (this.showLights) {
        this.drawLights(ctx)
      }
      this.drawRoomName(ctx)
    },
    defineSizeCanvas () {
      let size = 0
      if (this.$refs.house !== null) {
        const canvasHeight = this.$refs.house.clientHeight
        const canvasWidth = this.$refs.house.clientWidth

        if (canvasHeight < canvasWidth) {
          size = canvasHeight
        } else {
          size = canvasWidth
        }
        this.sizeCanvas = size
        this.ratio = window.devicePixelRatio
      }
    },
    /**
      * @description  renvoi la pièce sur laquelle le curseur se trouve
      * @param        event evènement du curseur
    */
    defineRoomOver () {
      if (this.roomBarycentres === {}) {
        return null
      }

      let roomOver = null
      // Scaled à la room
      const x = Math.round(this.cursor[0])
      const y = Math.round(this.cursor[1])

      for (const [name, roomBarycentre] of Object.entries(this.roomBarycentres)) {
        const distance = Math.sqrt(Math.pow(this.scaled(roomBarycentre[0]) - x, 2) + Math.pow(this.scaled(roomBarycentre[1]) - y, 2))

        if (distance <= this.sizeCircle * this.ratio) {
          roomOver = name
        }
      }

      this.roomOver = roomOver
    },
    hourToInt (hour) {
      if (hour !== undefined || hour !== null) {
        return parseInt(hour.substring(0, 2))
      } else {
        return 0
      }
    }
  },
  watch: {
    hourSelected () {
      this.drawRooms()
      this.drawSunAndLights()
    },
    seasonSelected () {
      this.drawRooms()
      this.drawSunAndLights()
    },
    roomSelected () {
      this.drawRooms()
      this.drawSunAndLights()
    },
    sizeCanvas () {
      this.drawRooms()
      this.drawSunAndLights()
    },
    pictureBackground () {
      this.isLoaded = false
    },
    cursor () {
      this.defineRoomOver()
    },
    ratio () {
      this.drawRooms()
      this.drawSunAndLights()
    }
  },
  created () {
    window.addEventListener('resize', this.defineSizeCanvas)
  },
  mounted () {
    this.defineSizeCanvas()
    this.drawRooms()

    const that = this

    lightOn.src = require('../assets/img/light_on.svg')
    lightOn.onload = function () {
      that.drawSunAndLights()
    }

    sunPicture.src = require('../assets/img/sun_plan.svg')
    sunPicture.onload = function () {
      that.drawSunAndLights()
    }
  },
  updated () {
    this.defineSizeCanvas()
    this.drawRooms()
    this.drawSunAndLights()
  },
  unmounted () {
    window.removeEventListener('resize', this.defineSizeCanvas)
  }
}
</script>

<style scoped>
#house {
  position:absolute;
  width: 100%;
  height: 100%;
}
.img {
  height: auto;
  width: 100%;
}
.divOverlay, #imgBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}

.spinnerOverlay {
  z-index: 6;
  position: absolute;
  top: calc(50% - 25px);
}

.pointer {
  cursor: pointer;
}

.animation {
  animation: pulse 2s ease-in-out 0s alternate infinite none running;
}
</style>
