<template>
  <div :class="{ 'strong-point': true, 'link': clickable}" :title="title" @click="showCertificate = !showCertificate">
    <div class="icon" :style="{ backgroundImage: 'url(' + infos.image + ')' }">
    </div>
    <div class="d-flex flex-column flex-grow-1">
        <div class="title" :style="{'color': infos.color}">{{ infos.title }}</div>
        <div class="legend">{{ infos.legend }}</div>
    </div>
    <div v-if="clickable"><span class="cs-icon icon-open-new-tab"></span></div>
  </div>
  <the-certificate-viewer v-if="clickable" :showLocaleAverages="showLocaleAverages" :triggerModal="showCertificate" :roomData="roomData" :seasonData="seasonData" :resultType="type" :roomType="roomType" :roomNumber="roomNumber"></the-certificate-viewer>
</template>

<script>
import TheCertificateViewer from './TheCertificateViewer.vue'
import { orientationString, roomTypeString, arrayString } from '../util/filters.js'

export default {
  components: { TheCertificateViewer },
  name: 'base-strong-point',
  props: {
    roomSelected: String,
    type: String,
    showLocaleAverages: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    seasonData: {
      type: Object,
      default: function () {
        return { autumn: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, winter: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, summer: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, spring: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } } }
      }
    },
    roomsData: Map,
    seasonSelected: {
      type: String,
      default: function () {
        return 'summer'
      }
    },
    propertyData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    orientationString: orientationString,
    roomTypeString: roomTypeString,
    arrayString: arrayString
  },
  data () {
    return {
      showCertificate: false,
      colors: { sunlight: '#F8CB15', natural_light: '#008FC8', window_surface: '#008FC8', exterior: '#2ECC71', multiple_orientation: '#CD5C5C', orientation: '#FF8700' }
    }
  },
  computed: {
    value () {
      if (this.roomSelected && this.roomsData.get(this.roomSelected) !== null) {
        if (this.type === 'natural_light' || this.type === 'sunlight') {
          const average = this.roomData[this.seasonSelected].averages[this.type].split(':')
          return average[0] + 'h' + average[1]
        } else if (this.type === 'window_surface') {
          return this.roomsData.get(this.roomSelected).window_surface
        }
      }

      return null
    },
    roomData () {
      if (this.roomSelected && this.roomsData !== null) {
        return this.roomsData.get(this.roomSelected).seasons_data
      } else {
        return { autumn: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } }, winter: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } }, summer: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } }, spring: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, slots: { sunlight: [], natural_light: [] } } }
      }
    },
    roomOrientations () {
      const orientationsString = []
      if (this.roomSelected && this.roomsData.get(this.roomSelected) !== null) {
        const orientations = this.roomsData.get(this.roomSelected).orientations
        orientations.forEach(orientation => orientationsString.push(this.orientationString(orientation)))
      } else {
        this.propertyData.forEach(property => property.orientations.forEach(orientation => orientationsString.push(this.orientationString(orientation))))
      }

      return orientationsString
    },
    roomType () {
      if (this.roomSelected && this.roomsData.get(this.roomSelected) !== null) {
        return this.roomsData.get(this.roomSelected).room_type
      } else {
        return 0
      }
    },
    roomNumber () {
      if (this.roomSelected && this.roomsData.get(this.roomSelected) !== null) {
        return this.roomsData.get(this.roomSelected).room_nbr
      } else {
        return 0
      }
    },
    infos () {
      const infos = { title: '', legend: '', color: '#000000', image: '' }
      infos.image = require('../assets/img/strong-point/' + this.type + '.svg')
      infos.color = this.colors[this.type]
      let title = ''
      let legend = ''

      if (this.type === 'sunlight') {
        if (this.roomSelected !== null) {
          title = 'Pièce ensoleillée'
          if (this.value !== '00h00') {
            legend = this.value + ' en moyenne par jour'
          }
        } else {
          if (this.propertyData.length === 1) {
            title = this.roomTypeString(this.propertyData[0].room_type, this.propertyData[0].room_nbr) + ' ensoleillé'
            if (this.propertyData[0].room_type >= 1 && this.propertyData[0].room_type <= 3) {
              title += 'e'
            }
          } else {
            const roomType = new Set()
            this.propertyData.forEach(element => roomType.add(element.room_type))
            if (roomType.size > 1) {
              title = 'Bien ensoleillé'
            } else {
              title = this.roomTypeString(this.propertyData[0].room_type, 0) + 's ensoleillé'
              if (this.propertyData[0].room_type >= 1 && this.propertyData[0].room_type <= 3) {
                title += 'e'
              }
              title += 's'
            }
          }
        }
      } else if (this.type === 'natural_light') {
        if (this.roomSelected !== null) {
          title = 'Pièce lumineuse'
          if (this.value !== '00h00') {
            legend = this.value + ' en moyenne par jour'
          }
        } else {
          if (this.propertyData.length === 1) {
            title = this.roomTypeString(this.propertyData[0].room_type, this.propertyData[0].room_nbr)
            if (this.propertyData[0].room_type >= 1 && this.propertyData[0].room_type <= 3) {
              title += ' lumineuse'
            } else {
              title += ' lumineux'
            }
          } else {
            const roomType = new Set()
            this.propertyData.forEach(element => roomType.add(element.room_type))
            if (roomType.size > 1) {
              title = 'Bien lumineux'
            } else {
              title = this.roomTypeString(this.propertyData[0].room_type, 0) + 's'
              if (this.propertyData[0].room_type >= 1 && this.propertyData[0].room_type <= 3) {
                title += ' lumineuses'
              } else {
                title += ' lumineux'
              }
            }
          }
        }
      } else if (this.type === 'window_surface') {
        title = 'Surfaces vitrées importantes'
        if (this.roomSelected !== null) {
          legend = this.value + ' m² de vitrage dans la pièce'
        }
      } else if (this.type === 'exterior') {
        title = 'Espace extérieur'
      } else if (this.type === 'multiple_orientation' && this.roomOrientations.length > 1) {
        if (this.roomOrientations.length === 2) {
          title = 'Double orientation'
        } else if (this.roomOrientations.length === 3) {
          title = 'Triple orientation'
        } else {
          title = 'Multiples orientations'
        }

        legend = this.arrayString(this.roomOrientations)
      } else if (this.type === 'orientation' && this.roomOrientations.length === 1) {
        title = 'Orientation ' + this.roomOrientations[0]
      }

      infos.title = title
      infos.legend = legend
      return infos
    },
    clickable () {
      if ((this.type === 'natural_light' || this.type === 'sunlight') && this.roomSelected !== null) {
        return true
      } else {
        return false
      }
    },
    title () {
      if (this.clickable) {
        return 'Voir les résultats détaillés'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
  .strong-point {
    border-radius: 10px;
    padding: 6px 10px;
    display: flex;
    text-align: left;
    align-items: center;
  }

  .link {
    cursor: pointer;
  }

  .link:hover {
    background-color: #E5E6EB;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .legend {
    font-size: 13px;
    font-weight: 300;
  }

  .strong-point .cs-icon {
    color:#1c62b4;
    margin-right: 6px;
  }

  .icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 0 32px;
    height: 32px;
    margin-right: 15px;
  }

  @media (max-height: 700px) {
    .title {
      font-size: 20px;
      line-height: 24px;
    }

    .legend {
      font-size: 13px;
    }

    .icon {
      flex: 0 0 42px;
      height: 42px;
    }
  }
</style>
