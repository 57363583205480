<template>
  <div>
    <div class="title">{{ title }}</div>
    <div class="d-flex flex-wrap">
      <div v-for="(item, index) in list" :key="index" :for="formatId(item)">
          <input type="checkbox" class="filterButton" v-model="filterValues" :value="index" :id="formatId(item)">
          <label :for="formatId(item)">{{ item }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-filter-button',
  props: {
    title: String,
    list: Object,
    filterSelected: Array
  },
  emits: ['update:filterSelected'],
  methods: {
    formatId (text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-').toLowerCase()
    }
  },
  computed: {
    filterValues: {
      get () {
        return this.filterSelected
      },
      set (value) {
        this.$emit('update:filterSelected', value)
      }
    }
  }
}
</script>

<style scoped>

.title {
  font-size: 17px;
}

label:hover {
  background-color: #EBEBEB;
}

label, input {
  cursor: pointer;
}

input[type="checkbox"].filterButton {
  display: none;
}

.d-flex > div {
  margin-right: 10px;
}

.d-flex > div:last-child {
  margin-right: 0px;
}

.d-flex > div {
  margin-top: 10px;
}

input[type="checkbox"].filterButton + label {
  border: 1px solid #1C62B4;
  border-radius: 12px;
  color: #1C62B4;
  padding: 10px;
  min-width: 36px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
}

input[type="checkbox"].filterButton:checked + label {
  background-color: #1C62B4;
  color: white;
}
</style>
