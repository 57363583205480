<template>
  <div v-if="roomSelected && strongPoints.length > 0 || strongPoints.size > 0" class="box">
    <div class="title">{{ title }}</div>
    <div class="inside-box">
      <div v-if="roomSelected" class="content">
        <div v-for="(point, index) in strongPoints" :key="index">
          <base-strong-point :type="point" :showLocaleAverages="showLocaleAverages" :roomSelected="roomSelected" :seasonData="seasonData" :roomsData="roomsData" :seasonSelected="seasonSelected"></base-strong-point>
        </div>
      </div>
      <div v-else class="content">
        <div v-for="[point, values] in strongPoints" :key="point">
          <base-strong-point :type="point" :roomSelected="null" :propertyData="values"></base-strong-point>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseStrongPoint from './BaseStrongPoint.vue'

export default {
  name: 'the-property-page-strong-point',
  props: {
    roomSelected: String,
    seasonSelected: String,
    showLocaleAverages: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showNaturalLight: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    seasonData: {
      type: Object,
      default: function () {
        return { autumn: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, winter: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, summer: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } }, spring: { averages: { sunlight: '00:00:00', natural_light: '00:00:00' }, daytime: { start: '06:00:00', end: '21:00:00' } } }
      }
    },
    roomsData: Map
  },
  components: {
    BaseStrongPoint
  },
  computed: {
    title () {
      if (this.roomSelected == null) {
        return 'Les points forts du bien'
      } else {
        return 'Les points forts de la pièce'
      }
    },
    strongPoints () {
      if (this.roomSelected !== null) {
        return this.roomsData.get(this.roomSelected).strong_points
      } else {
        const pointsObject = {}
        this.roomsData.forEach((values, room) => {
          values.strong_points.forEach(point => {
            // On inclus l'orientation comme point fort uniquement si c'est un séjour
            if (['multiple_orientation', 'orientation'].includes(point) === false || values.room_type === 0) {
              if (point in pointsObject === false) {
                pointsObject[point] = []
              }
              pointsObject[point].push({ room_type: values.room_type, room_nbr: values.room_nbr, orientations: values.orientations })
            }
          })
        })

        const pointsObjectOrdered = new Map()
        const pointsType = ['natural_light', 'sunlight', 'exterior', 'window_surface', 'multiple_orientation', 'orientation']
        for (let i = 0; i < pointsType.length; i++) {
          if (pointsType[i] in pointsObject) {
            pointsObjectOrdered.set(pointsType[i], pointsObject[pointsType[i]])
          }
        }
        return pointsObjectOrdered
      }
    }
  }
}
</script>

<style scoped>
  .title {
    font-weight: 500;
    text-align: left;
    margin-bottom: 6px;
  }

  .box {
    background-color: white;
    border-radius: 10px;
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 18px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .inside-box {
    position: relative;
    flex: 1;
  }

  .content {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content > div:last-child {
    margin-bottom: 0px;
  }

  @media (max-height: 700px) {
    .box {
        padding: 10px 18px;
        margin-top: 10px;
    }
  }
</style>
