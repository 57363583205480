<template>
  <header>
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="fs-2">
          {{ projectName }}
        </div>
        <div class="d-flex align-self-center">
          <div class="developer-logo" :title="developerName" v-if="developerLogo" :style="{ backgroundImage: 'url(' + developerLogo + ')' }"></div>
          <img class="solen-logo" src="../assets/img/logo_solen.svg">
        </div>
      </div>
    </div>
  </header>

  <div v-if="!showCsWidget" class="container map-box">
    <the-project-page-map class="map" :address="projectAddress" :latitude="gpsCoordinates.latitude" :longitude="gpsCoordinates.longitude"></the-project-page-map>
  </div>

  <main role="main" class="container solen-shadow position-relative">
    <div v-if="showFilters" :class="['list-filters', 'd-none', 'd-md-block', {'list-filters-cs': showCsWidget}]" tabindex="0" @focusout="showFilters = false">
      <base-filter-button v-if="Object.keys(filtersList.roomCount).length > 1" title="Nombre de pièces" :list="filtersList.roomCount" v-model:filter-selected="filtersSelected.roomCount"></base-filter-button>
      <base-filter-button v-if="Object.keys(filtersList.propType).length > 1" title="Type de bien" :list="filtersList.propType" v-model:filter-selected="filtersSelected.propType"></base-filter-button>
      <base-filter-button v-if="Object.keys(filtersList.floor).length > 1" title="Etage" :list="filtersList.floor" v-model:filter-selected="filtersSelected.floor"></base-filter-button>
      <base-filter-button v-if="Object.keys(filtersList.orientation).length == 4" title="Orientation" :list="filtersList.orientation" v-model:filter-selected="filtersSelected.orientation"></base-filter-button>
      <base-filter-button title="+ de filtres" :list="filtersList.other" v-model:filter-selected="filtersSelected.other"></base-filter-button>
      <div v-on:click="resetFilter()" @click="showFilters = false" class="text-center reset-card">Réinitialiser les filtres</div>
    </div>
    <div v-if="showCsWidget" class="widget d-none d-md-block">
        <the-property-page-cs-widget :cs-id="cityscanConfig.id">
          </the-property-page-cs-widget>
    </div>
    <div :class="['list-properties', 'd-flex', 'flex-column', {'list-properties-cs': showCsWidget}]">
        <div class="d-flex search-box">
          <div @click="showFilters = !showFilters" class="filter-icon d-none d-md-flex position-relative">
            <span class="cs-icon icon-filter-medium"></span>
            <div class="round-blue" v-if="filtersSelectedBool"></div>
          </div>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Rechercher par nom" aria-label="Rechercher" v-model="searchText" aria-describedby="search">
            <span class="input-group-text cs-icon icon-analyse" id="search"></span>
          </div>
        </div>
        <div :class="['cards', {'row': !showCsWidget}, 'justify-content-center']">
          <the-project-page-tile v-for="(property, index) in properties"
            v-show="property.show"
            :key="index"
            :name="property.name"
            :projectUrl="projectUrl"
            :propertyUrl="property.url"
            :roomCount="property.roomCount"
            :showCsWidget="showCsWidget"
            :floor="property.floor"
            :propType="property.propType"
            :surface="property.surface"
            :orientation="property.orientation"
            :planImg="property.planImg"></the-project-page-tile>
        </div>
        <div v-show="noPropertiesToShow" class="text-center pt-4">
          <div v-if="filtersSelectedBool">Aucun bien ne correspond à vos critères</div>
          <div v-else class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Chargement en cours...</span>
            </div>
          </div>
        </div>
    </div>
  </main>

  <footer class="mt-auto">
    <div class="container d-flex justify-content-end align-items-center">
      <div>© Solen {{ currentYear }}</div>
      <div class="legal">Documents et informations non contractuels</div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'
import BaseFilterButton from './BaseFilterButton.vue'
import ThePropertyPageCsWidget from './ThePropertyPageCsWidget.vue'
import TheProjectPageTile from './TheProjectPageTile.vue'
import TheProjectPageMap from './TheProjectPageMap.vue'
import { roomCountString, propTypeString, floorStringFilter, orientationString, otherString } from '../util/filters.js'

export default {
  name: 'the-project-page',
  props: {
    projectUrl: String
  },
  components: {
    ThePropertyPageCsWidget,
    TheProjectPageTile,
    TheProjectPageMap,
    BaseFilterButton
  },
  data () {
    return {
      projectName: '',
      developerName: '',
      developerLogo: '',
      projectAddress: '',
      gpsCoordinates: {
        latitude: 0,
        longitude: 0
      },
      showNaturalLight: true,
      showCsWidget: false,
      showFilters: false,
      currentYear: new Date().getFullYear(),
      filtersList: {
        roomCount: {},
        propType: {},
        floor: {},
        orientation: { N: orientationString('N'), E: orientationString('E'), S: orientationString('S'), W: orientationString('W') },
        other: { natural_light: otherString('natural_light'), sunlight: otherString('sunlight') }
      },
      filtersSelected: {
        roomCount: [],
        propType: [],
        floor: [],
        orientation: [],
        other: []
      },
      searchText: '',
      properties: [],
      cityscanConfig: { id: null, report_url: '' }
    }
  },
  computed: {
    filtersSelectedBool () {
      for (const key in this.filtersSelected) {
        if (this.filtersSelected[key].length > 0) {
          return true
        }
      }

      if (this.searchText !== '') {
        return true
      }
      return false
    },
    noPropertiesToShow () {
      for (const key in this.properties) {
        if (this.properties[key].show === true) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    removeFilter (key, value) {
      this.filtersSelected[key].splice(this.filtersSelected[key].indexOf(value), 1)
    },
    resetFilter () {
      for (const key in this.filtersSelected) {
        this.filtersSelected[key] = []
      }
      this.searchText = ''
    },
    roomCountString: roomCountString,
    propTypeString: propTypeString,
    floorStringFilter: floorStringFilter,
    orientationString: orientationString,
    otherString: otherString,
    filterString (key, value) {
      if (key === 'roomCount') {
        return value.toString()
      } else if (key === 'propType') {
        return propTypeString(value)
      } else if (key === 'floor') {
        return floorStringFilter(value)
      } else if (key === 'orientation') {
        return orientationString(value)
      } else if (key === 'other') {
        return otherString(value)
      } else {
        return ''
      }
    },
    async getProjectApi () {
      await axios.get('/api/v3/projects/' + this.projectUrl + '/')
        .then((response) => {
          const res = response.data
          const floorExisting = new Set()
          const roomCountExisting = new Set()
          const propTypeExisting = new Set()
          let orientationExisting = true
          let exteriorExisting = false

          // Données du projet
          this.projectName = res.project.name
          this.projectAddress = res.project.address
          this.developerLogo = res.developer.logo
          this.developerName = res.developer.name
          this.gpsCoordinates.latitude = parseFloat(res.location_data.latitude)
          this.gpsCoordinates.longitude = parseFloat(res.location_data.longitude)

          // Paramètres de la plateforme
          this.showNaturalLight = !res.platform_options.desactivate_natural_light
          if (!this.showNaturalLight) {
            this.filtersList.other = { sunlight: otherString('sunlight') }
          }

          if (Object.keys(res.cityscan).length === 0) {
            this.showCsWidget = false
          } else {
            this.showCsWidget = true
            this.cityscanConfig = res.cityscan
          }

          // Données des biens
          for (const key in res.properties) {
            const prop = res.properties[key]

            var p = {}
            p.name = prop.name
            p.url = prop.id
            p.roomCount = prop.room_count
            p.floor = prop.floor
            p.levelCount = prop.level_count
            if (p.levelCount > 1) {
              // Duplex
              p.propType = 3
            } else {
              p.propType = prop.building_type
            }
            p.surface = prop.surface
            p.orientation = prop.default_room.orientation
            p.exterior = prop.exterior
            p.planImg = prop.plan_img
            // TODO after API updated
            p.sunlight = true
            p.natural_light = true
            p.show = true
            this.properties.push(p)

            // Filtres
            floorExisting.add(p.floor)
            roomCountExisting.add(p.roomCount)
            propTypeExisting.add(p.propType)
            if (p.exterior === true) {
              exteriorExisting = true
            }
            if (p.orientation === null) {
              orientationExisting = false
            }
          }

          // Paramétrage des filtres
          Array.from(floorExisting).sort().forEach(function (floorIn) {
            this.filtersList.floor[floorIn] = this.filterString('floor', floorIn)
          }, this)

          Array.from(roomCountExisting).sort().forEach(function (roomCountIn) {
            this.filtersList.roomCount[roomCountIn] = this.filterString('roomCount', roomCountIn)
          }, this)

          Array.from(propTypeExisting).sort().forEach(function (typeIn) {
            this.filtersList.propType[typeIn] = this.filterString('propType', typeIn)
          }, this)

          // On supprime le filtre Orientation si certains bien n'ont pas l'information
          if (!orientationExisting) {
            this.filtersList.orientation = {}
          }

          // On ajoute le filtre "Espace exterieur" si il y en a dans le projet
          if (exteriorExisting) {
            this.filtersList.other.exterior = otherString('exterior')
          }
        })
        .catch((error) => {
          let url = this.projectUrl
          if (error.response && error.response.status === 404) {
            if (url === undefined) {
              url = '404'
            }
            this.$router.push({ name: 'Error404', params: { catchAll: url } })
          } else {
            if (url === undefined) {
              url = '500'
            }
            this.$router.push({ name: 'Error500', params: { catchAll: url } })
          }
        })
    },
    setFilteredProperties () {
      for (const key in this.properties) {
        const prop = this.properties[key]
        const selectedProp = []

        for (const criteria in this.filtersSelected) {
          if (criteria === 'other') {
            for (const criteriaKey in this.filtersSelected[criteria]) {
              const otherCriteria = this.filtersSelected[criteria][criteriaKey]

              if (prop[otherCriteria] === false) {
                selectedProp.push(false)
              } else {
                selectedProp.push(true)
              }
            }
          } else if (criteria === 'orientation') {
            const selectedOrient = []

            for (const criteriaKey in this.filtersSelected[criteria]) {
              const orientationSelected = this.filtersSelected[criteria][criteriaKey]

              if (!prop[criteria].toString().includes(orientationSelected)) {
                selectedOrient.push(false)
              } else {
                selectedOrient.push(true)
              }
            }

            if (selectedOrient.includes(true) || selectedOrient.length === 0) {
              selectedProp.push(true)
            } else {
              selectedProp.push(false)
            }
          } else {
            if (this.filtersSelected[criteria].length > 0 && !this.filtersSelected[criteria].includes(prop[criteria].toString())) {
              selectedProp.push(false)
            } else {
              selectedProp.push(true)
            }
          }
        }

        // Recherche par texte
        if (this.searchText.length > 0 && !prop.name.includes(this.searchText)) {
          selectedProp.push(false)
        } else {
          selectedProp.push(true)
        }

        if (!selectedProp.includes(false)) {
          this.properties[key].show = true
        } else {
          this.properties[key].show = false
        }
      }
    }
  },
  mounted () {
    this.getProjectApi()
  },
  watch: {
    projectName () {
      let title = process.env.VUE_APP_TITLE

      if (this.projectName !== '') {
        title = this.projectName + ' - ' + title
      }

      document.title = title
    },
    filtersSelected: {
      handler () {
        this.setFilteredProperties()
      },
      deep: true
    },
    searchText () {
      this.setFilteredProperties()
    }
  }
}
</script>

<style scoped>

.list-properties {
  width: 100%;
  background-color: white;
  border-left: 1px solid #E5E6EB;
}

@media (min-width: 768px) {
  .list-properties-cs {
    width: 340px;
    border-left: 0px;
  }
}

.list-properties > .cards {
  overflow:auto;
  margin-bottom: 3px;
  margin-right: 3px;
  margin-top: 3px;
}

.widget {
  flex-grow: 1;
  position: relative;
  border-right: 1px solid #E5E6EB;
  background-color: white;
}

header {
  padding: 14px 0px;
}

main {
  flex: 1 1 auto;
  display: flex;
  border-radius: 10px;
  padding: 0px;
  height: 100%;
  overflow: hidden;
  background-color: white;
}

footer {
  font-size: 0.9em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.legal {
  font-size: 0.8em;
  padding-left: 20px;
}

.search-box {
  padding: 3px;
  border-bottom: 1px solid #E5E6EB;
}

.search-box .cs-icon {
  color:#1C62B4;
}

.filter-icon {
  border-right: 1px solid #E5E6EB;
  border-radius: 0px;
}

.filter-icon .cs-icon {
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  display: flex;
}

.filter-icon:hover {
  background-color: #E5E6EB;
  border-radius: 10px;
  cursor:pointer;
}

.form-control, .input-group-text {
  border: none;
  background-color: white;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.list-filters {
  background-color: white;
  width:400px;
  padding: 8px 16px;
  margin: 8px 0px;
  margin-right: 8px;
  padding-right: 8px;
  overflow: auto;
}

.list-filters-cs {
  background-color: white;
  width:300px;
  padding: 16px;
  margin: 0px;
  position:absolute;
  right:340px;
  top:0px;
  z-index:99;
  border: 1px solid #E5E6EB;
  border-top: 0px;
  border-bottom-left-radius: 10px;
}

.list-filters > div {
  margin-bottom: 10px;
}

.list-filters > div:last-child {
  margin-bottom: 0px;
}

.reset-card {
  text-decoration: underline;
  font-size: 0.9em;
  padding: 0px 10px;
  line-height: 2em;
  cursor: pointer;
  color:rgba(0,0,0,.5);
}

.reset-card:hover {
  color:#6c757d;
}

.solen-logo {
  height: 40px;
}

.map-box {
  padding: 0px;
  margin-bottom: 14px;
}

.map-box, .map {
  height: 200px;
}

.developer-logo {
    margin-right: 20px;
    height: 48px;
    width: 140px;
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
}

.round-blue {
  position: absolute;
  bottom: 4px;
  right: 4px;
  shape-outside: circle();
  background: #1C62B4;
  clip-path: circle();
  width: 10px;
  height: 10px;
}
</style>
