<template>
  <div class="open-text" v-if="!comparatorStatus" @click="showComparator">
    <span class="cs-icon icon-luminosity"></span> Comparer la luminosité
  </div>
  <div class="visualisator" v-if="comparatorStatus">
    <div class="frame-image">
      <div class="close-button" @click="hideComparator" title="Fermer le comparateur">
        <span class="cs-icon icon-Bold_close"></span>
      </div>
      <div id="visualisateur">
        <transition name="fade-sun">
          <div v-show="pic_sun==='bedroom-sun-summer'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-sun-summer.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='bedroom-sun-spring'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-sun-spring.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='bedroom-sun-winter'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-sun-winter.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='bedroom-sun-autumn'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-sun-autumn.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-1100'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-1100.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-1030'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-1030.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-961'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-961.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-892'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-892.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-823'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-823.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-753'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-753.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-684'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-684.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-615'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-615.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-546'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-546.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-477'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-477.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-408'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-408.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-338'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-338.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-269'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-269.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-200'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-200.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-100'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-100.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='bedroom-0'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/bedroom-0.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='livingroom-sun-summer'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-sun-summer.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='livingroom-sun-spring'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-sun-spring.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='livingroom-sun-winter'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-sun-winter.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade-sun">
          <div v-show="pic_sun==='livingroom-sun-autumn'" class="img img-sun" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-sun-autumn.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-1100'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-1100.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-1030'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-1030.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-961'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-961.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-892'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-892.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-823'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-823.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-753'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-753.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-684'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-684.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-615'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-615.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-546'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-546.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-477'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-477.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-408'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-408.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-338'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-338.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-269'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-269.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-200'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-200.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-100'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-100.jpg') + ')' }">
          </div>
        </transition>
        <transition name="fade">
          <div v-show="pic_lux==='livingroom-0'" class="img" :style="{ backgroundImage: 'url(' + require('@/assets/img/lux/livingroom-0.jpg') + ')' }">
          </div>
        </transition>
      </div>
    </div>
    <div class="legend">Comparaison donnée à titre indicatif</div>
  </div>
</template>

<script>

export default {
  name: 'the-property-page-comparator',
  data () {
    return {
      imagesLux: [0, 100, 200, 269, 338, 408, 477, 546, 615, 684, 753, 823, 892, 961, 1030, 1100]
    }
  },
  emits: ['change-comparator-status'],
  props: {
    comparatorStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    roomSelected: String,
    seasonSelected: String,
    hourSelected: Number,
    roomsData: Map
  },
  computed: {
    roomType: function () {
      if (this.roomSelected && this.roomsData.get(this.roomSelected) !== null) {
        return this.roomsData.get(this.roomSelected).room_type
      } else {
        return 0
      }
    },
    isSunshine: function () {
      let sunshine = false

      if (this.roomSelected !== null) {
        const slots = this.roomsData.get(this.roomSelected).seasons_data[this.seasonSelected].slots.sunlight
        slots.forEach(slot => {
          const beginHour = this.hourToInt(slot.start)
          const endHour = this.hourToInt(slot.end)
          if (this.hourSelected >= beginHour && this.hourSelected < endHour && slot.legend === 1) {
            sunshine = true
          }
        })
      }
      return sunshine
    },
    luxValue: function () {
      if (this.roomSelected !== null) {
        let hourString = this.hourSelected.toString()
        if (hourString.length < 2) {
          hourString = '0' + hourString
        }
        hourString = hourString + ':00:00'

        return this.roomsData.get(this.roomSelected).seasons_data[this.seasonSelected].lux[hourString]
      } else {
        return 500
      }
    },
    pic_lux: function () {
      let lux = 0

      for (let i = 0; i < this.imagesLux.length; i++) {
        if (this.imagesLux[i] <= this.luxValue) {
          lux = i
        }
      }

      return this.room + '-' + this.imagesLux[lux]
    },
    pic_sun: function () {
      if (this.isSunshine) {
        return this.room + '-sun-' + this.seasonSelected
      } else {
        return null
      }
    },
    room: function () {
      if (this.roomType === 1) {
        return 'bedroom'
      } else {
        return 'livingroom'
      }
    }
  },
  methods: {
    hourToInt (hour) {
      if (hour !== undefined || hour !== null) {
        return parseInt(hour.substring(0, 2))
      } else {
        return 0
      }
    },
    showComparator () {
      this.$emit('change-comparator-status', true)
    },
    hideComparator () {
      this.$emit('change-comparator-status', false)
    },
    async loadImageLux (lux) {
      var livingroom = new Image()
      livingroom.src = require('@/assets/img/lux/livingroom-' + lux + '.jpg')
      var bedroom = new Image()
      bedroom.src = require('@/assets/img/lux/bedroom-' + lux + '.jpg')
    },
    async loadImageSeason (season) {
      var livingroomSeason = new Image()
      livingroomSeason.src = require('@/assets/img/lux/livingroom-sun-' + season + '.jpg')
      var bedroomSeason = new Image()
      bedroomSeason.src = require('@/assets/img/lux/bedroom-sun-' + season + '.jpg')
    }
  },
  mounted () {
    // Preload images
    this.imagesLux.forEach(lux => { this.loadImageLux(lux) })
    const seasons = ['spring', 'summer', 'autumn', 'winter']
    seasons.forEach(season => { this.loadImageSeason(season) })
  }
}
</script>

<style scoped>
.open-text {
  color: #1C62B4;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 7px 9px;
  font-weight: 500;
  border-radius: 5px;
}

.open-text:hover {
  background-color: #E5E6EB;
  color: #2071ce;
}

.open-text > .cs-icon {
  margin-right: 8px;
  font-size: 16px;
  font-weight: bold;
}

.visualisator {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.frame-image {
  height: 140px;
  width: 200px;
  position: relative;
}

@media (min-width: 1700px) {
  .frame-image {
    height: 200px;
    width: 280px;
    position: relative;
  }
}

.close-button {
  color: white;
  position: absolute;
  text-align: center;
  right: 8px;
  top: 8px;
  cursor: pointer;
  font-size: 12px;
  z-index: 99;
  width: 18px;
  height: 18px;
  background-color: #1C62B4;
  border-radius: 20px;
}

.close-button > .cs-icon {
  z-index: 100;
  font-size: 8px;
}

.legend {
  color: #1C62B4;
  font-size: 12px;
  font-style: italic;
  text-shadow: #FFFFFF 0px 0px 2px;
}

.img {
  width:100%;
  height:100%;
  border-radius: 10px;
  background-size:cover;
  background-position:bottom;
  background-repeat:no-repeat;
  position:absolute;
  top:0px;
  z-index: 1;
}

.img-sun {
  opacity: 0.6;
  z-index: 3;
}

.fade-enter {
  opacity:1;
  z-index:1;
}

.fade-leave-active {
  transition: opacity  0.5s;
}

.fade-leave-to {
  opacity: 0;
  z-index:2;
}

.fade-leave {
  z-index:2;
}

.fade-sun-enter {
  opacity:0.6;
  z-index:3;
}

.fade-sun-leave-active {
  transition: opacity  0.5s;
}

.fade-sun-leave-to {
  opacity: 0;
  z-index:4;
}

.fade-sun-leave {
  z-index:4;
}

.visu-enter-active, .visu-leave-active {
  transition: opacity .5s;
}
.visu-enter, .visu-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
