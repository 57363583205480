<template>
  <div class="modal fade" :class="{show, 'd-block': active}" id="modal" aria-hidden="true" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content modal-content-solen solen-shadow">
        <div class="modal-header modal-header-solen">
          <h5 class="modal-title" id="modalLabel">Partager la plateforme par email</h5>
          <button v-if="!sending && !sent" type="button" class="btn-close" @click="toggleModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="!sending && !sent">
            <div class="mb-3">
              <label for="emailInput" class="form-label">Adresse email</label>
              <input type="email" class="form-control" :class="{ 'is-invalid': hasError }" id="emailInput" required aria-describedby="emailHelp" placeholder="Adresse email" v-model="email">
              <div id="validationEmail" class="invalid-feedback">
                Veuillez renseigner une adresse email valide
              </div>
              <div v-if="projectUrl !== 'demo'" id="emailHelp" class="form-text">La personne recevra un email avec le lien de la plateforme</div>
              <div v-else id="emailHelp" class="form-text text-warning">Ceci étant un lien démo, la personne ne recevra pas d'email</div>
            </div>
            <div v-if="projectUrl !== undefined" class="mb-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" id="propertyAccess" value="property" v-model="urlToSend">
                <label class="form-check-label" for="propertyAccess">
                  Donner accès uniquement au bien <strong>{{ propertyName }}</strong>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="project" id="projectAccess" v-model="urlToSend">
                <label class="form-check-label" for="projectAccess">
                  Donner accès au programme entier <strong>{{ projectName }}</strong>
                </label>
              </div>
            </div>
            <a href="#" @click="sendForm" class="btn solen-button">Envoyer</a>
          </div>
          <div v-else-if="sending && !sent" class="text-center">
            <div class="spinnerOverlay spinner-border text-primary" role="status">
              <span class="visually-hidden">Chargement en cours...</span>
            </div>
          </div>
          <div v-else>
            <div v-if="!errorApi" class="sent">
              <span class="cs-icon icon-check-bold"></span> Email envoyé
            </div>
            <div v-else class="error">
              <span class="cs-icon icon-Bold_close"></span> Il y a eu une erreur lors de l'envoi, veuillez recommencer
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="active" class="modal-backdrop fade show"></div>
</template>

<script>
import axios from 'axios'

// CSRF Token pour les POST, PUT, DELETE avec l'API
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

export default {
  name: 'the-share-property-modal',
  props: {
    triggerModal: Boolean,
    propertyUrl: String,
    projectUrl: String,
    propertyName: String,
    projectName: String
  },
  data () {
    return {
      active: false,
      show: false,
      hasError: false,
      sending: false,
      sent: false,
      errorApi: false,
      email: '',
      urlToSend: 'property'
    }
  },
  computed: {
    projectUrlToSend () {
      if (this.urlToSend === 'project' && this.projectUrl !== undefined) {
        return this.projectUrl
      } else {
        return null
      }
    }
  },
  methods: {
    toggleModal () {
      const body = document.querySelector('body')
      this.active = !this.active
      this.active
        ? body.classList.add('modal-open')
        : body.classList.remove('modal-open')
      this.email = ''
      this.sending = false
      this.sent = false
      this.errorApi = false
      setTimeout(() => (this.show = !this.show), 10)
    },
    sendForm () {
      if (this.email !== '' && this.validateEmail(this.email)) {
        this.hasError = false
        this.sending = true
        this.sendEmailSharingPropertyApi()
      } else {
        this.hasError = true
      }

      return false
    },
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async sendEmailSharingPropertyApi () {
      await axios.post('/api/v3/properties/' + this.propertyUrl + '/share/', {
        email: this.email,
        projectUrl: this.projectUrlToSend
      })
        .then(() => {
          this.sending = false
          this.errorApi = false
          this.sent = true
          setTimeout(function () { this.toggleModal() }.bind(this), 3000)
        })
        .catch(() => {
          this.sending = false
          this.sent = true
          this.errorApi = true
          setTimeout(function () { this.toggleModal() }.bind(this), 3000)
        })
    }
  },
  watch: {
    triggerModal () {
      this.toggleModal()
    }
  }
}
</script>

<style scoped>
  .modal-content-solen {
    border: none;
    border-radius: 10px;
    position: absolute;
  }

  .modal-header-solen {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .modal-footer-solen {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .modal-title {
    font-size: 28px;
  }

  .solen-button {
    background-color: #1C62B4;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .solen-button:hover {
    background-color: #2071ce;
  }

  .sent {
    color: #198754;
    font-size: 18px;
    text-align: center;
  }

  .error {
    color: #842029;
    font-size: 18px;
    text-align: center;
  }

  .sent .cs-icon, .error .cs-icon {
    padding-right: 8px;
  }
</style>
