<template>
  <div :class="['gy-2', {'col-12 col-lg-6 col-xl-4': !showCsWidget}]">
    <router-link class="solen-link" :to="{ name: 'Property', params: { projectUrl: projectUrl, propertyUrl: propertyUrl }}">
      <div :class="['d-flex', 'flex-row', 'align-items-center', 'solen-tile', {'justify-content-center': !showCsWidget}]">
        <div class="plan" :style="{ backgroundImage: 'url(' + planImg + ')' }">
        </div>
        <div :class="['d-flex', 'flex-column', {'flex-fill': showCsWidget}]">
          <div class="title">{{ propTypeString(propType) }} {{ name }}</div>
          <div>{{ roomCountString(roomCount) }} - {{ floorString(floor) }}</div>
          <div v-if="surface">Surface : {{ numberString(surface) }} m²</div>
          <div v-if="orientation">Orientation principale : {{ orientationString(orientation) }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { roomCountString, propTypeString, floorString, orientationString, otherString, numberString } from '../util/filters.js'

export default {
  name: 'the-project-page-tile',
  props: {
    projectUrl: String,
    propertyUrl: String,
    name: String,
    roomCount: Number,
    floor: Number,
    propType: Number,
    surface: Number,
    orientation: String,
    planImg: String,
    showCsWidget: Boolean
  },
  methods: {
    roomCountString: roomCountString,
    propTypeString: propTypeString,
    floorString: floorString,
    orientationString: orientationString,
    otherString: otherString,
    numberString: numberString
  }
}
</script>

<style scoped>

.solen-link {
  color: #333333;
  text-decoration: none;
}

.solen-tile {
  background-color: white;
  border-radius: 10px;
  padding: 6px;
  cursor: pointer;
  line-height: 1.2em;
  font-size: 0.8em;
  margin: 1px 3px;
  border: 1px solid white;
  align-items: center;
}

.solen-tile:hover {
  border: 1px solid #86b7fe;
}

.title {
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 15px;
  line-height: 1em;
  color:#1C62B4;
}

.plan {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 96px;
  height: 96px;
  margin-right: 15px;
  margin-left: 5px;
}
</style>
