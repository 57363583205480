<template>
  <div v-if="indicators.length > 0" class="box">
    <div class="title d-flex justify-content-between align-items-center"><div>Les points forts du quartier</div><div class="cs-icon icon-logo_cs_by"></div></div>
    <div class="inside-box">
      <div class="content">
        <div v-for="(point, index) in indicators" :key="index">
          <base-indicator :indicator="point"></base-indicator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIndicator from './BaseIndicator.vue'

export default {
  name: 'the-property-page-cs-indicator',
  components: {
    BaseIndicator
  },
  props: {
    indicators: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .title {
    font-weight: 500;
    text-align: left;
    margin-bottom: 6px;
  }

  .box {
    background-color: white;
    border-radius: 10px;
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 18px;
    margin-top: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .title > .cs-icon {
    color:#E84242;
  }

  .inside-box {
    position: relative;
    flex: 1;
  }

  .content {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color:#1c62b4;
  }

  .content > div {
    margin-bottom: 10px;
  }

  .content > div:last-child {
    margin-bottom: 0px;
  }

  @media (max-height: 700px) {
    .box {
        padding: 10px 18px;
        margin-top: 10px;
    }
  }
</style>
