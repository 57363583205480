<template>
  <div class="d-flex legend">
    <div :class="['colors', {'gbxmlColors': gbxml }]"></div>
    <div class="d-flex flex-column justify-content-between">
      <div>Très lumineux</div>
      <div>Lumineux</div>
      <div>Lumière artificielle</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'the-property-page-legend',
  props: {
    gbxml: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  }
}
</script>

<style scoped>
.legend {
  height: 60px;
}

.colors {
  border-radius: 20px;
  width: 14px;
  border: solid 1px #1C62B4;
  background: linear-gradient(0deg, rgb(0, 20, 40) 0%, rgb(0, 143, 232) 30%, rgb(187, 222, 251) 60%, rgb(187, 222, 251) 100%);
}

.gbxmlColors {
  background: linear-gradient(0deg, rgb(113, 189, 251) 0%, rgb(255, 255, 255) 85%);
}

.legend > div:nth-child(2) {
  margin-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.legend > div > div {
  font-size: 12px;
  line-height: 12px;
  text-align: left;
}

</style>
