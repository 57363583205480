<template>
  <div ref="compass" class="w-100 h-100">
    <canvas ref="canvas" :width="Math.ceil(ratio * sizeCanvas)" :height="Math.ceil(ratio * sizeCanvas)" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }"></canvas>
  </div>
</template>

<script>
import WebFont from 'webfontloader'

export default {
  name: 'the-property-page-compass',
  props: {
    orientation: Number
  },
  data () {
    return {
      sizeCanvas: 120,
      sizeFontWithoutRatio: 16,
      marginFont: 6,
      miniRayonWithoutRatio: 5,
      ratio: window.devicePixelRatio
    }
  },
  computed: {
    orientationRad: function () {
      return this.orientation * Math.PI / 180
    },
    rayon: function () {
      return Math.ceil(this.ratio * (this.sizeCanvas) / 2)
    },
    miniRayon: function () {
      return this.ratio * this.miniRayonWithoutRatio
    },
    sizeFont: function () {
      return this.ratio * this.sizeFontWithoutRatio
    },
    xCentre: function () {
      return Math.ceil(this.ratio * this.sizeCanvas / 2)
    },
    yCentre: function () {
      return Math.ceil(this.ratio * this.sizeCanvas / 2)
    },
    angleNord: function () {
      return this.getModuloCircle(this.orientationRad + 1.5 * Math.PI)
    },
    angleEst: function () {
      return this.getModuloCircle(this.orientationRad)
    },
    angleSud: function () {
      return this.getModuloCircle(this.orientationRad + 0.5 * Math.PI)
    },
    angleOuest: function () {
      return this.getModuloCircle(this.orientationRad + Math.PI)
    }
  },
  methods: {
    getModuloCircle (angle) {
      if (angle >= 2 * Math.PI) {
        angle -= 2 * Math.PI
      }
      return angle
    },
    resizeCompass () {
      this.ratio = window.devicePixelRatio
      if (this.$refs.compass.clientWidth !== null) {
        this.sizeCanvas = this.$refs.compass.clientWidth
      }
    },
    /**
      * @description  dessinela boussole
      * @param        context context du canvas
      * @param        xCentre, yCentre coordonées centre canvas
      * @param        rayon rayon cercle canvas
      * @param        ratio ratio de l'écran
      * @param        orientationRad orientationRad pour dessin flèche
    */
    drawCompass () {
      const context = this.$refs.canvas.getContext('2d')
      const canvasHeight = this.$refs.canvas.height
      const canvasWidth = this.$refs.canvas.width
      context.clearRect(0, 0, canvasWidth, canvasHeight)

      // Trace les flèches
      this.drawArrowsCardinal(context)

      // Trace le text
      this.drawTextCardinal(context, this.angleNord, 'N')
      this.drawTextCardinal(context, this.angleEst, 'E')
      this.drawTextCardinal(context, this.angleSud, 'S')
      this.drawTextCardinal(context, this.angleOuest, 'O')
    },
    drawArrowsCardinal (context) {
      context.beginPath()
      context.lineWidth = 1 * this.ratio
      for (let alpha = 0; alpha < 4; alpha++) {
        const xPoint1 = this.xCentre + this.miniRayon * Math.cos(this.orientationRad - Math.PI / 4 + alpha * Math.PI / 2 - Math.PI / 4)
        const yPoint1 = this.yCentre + this.miniRayon * Math.sin(this.orientationRad - Math.PI / 4 + alpha * Math.PI / 2 - Math.PI / 4)
        const xPointe = this.xCentre + 3 / 4 * (this.rayon - this.sizeFont - this.marginFont * this.ratio) * Math.cos(this.orientationRad + alpha * Math.PI / 2 - Math.PI / 4)
        const yPointe = this.yCentre + 3 / 4 * (this.rayon - this.sizeFont - this.marginFont * this.ratio) * Math.sin(this.orientationRad + alpha * Math.PI / 2 - Math.PI / 4)
        const xPoint2 = this.xCentre + this.miniRayon * Math.cos(this.orientationRad + Math.PI / 4 + alpha * Math.PI / 2 - Math.PI / 4)
        const yPoint2 = this.yCentre + this.miniRayon * Math.sin(this.orientationRad + Math.PI / 4 + alpha * Math.PI / 2 - Math.PI / 4)
        context.moveTo(xPoint1, yPoint1)
        context.lineTo(xPointe, yPointe)
        context.lineTo(xPoint2, yPoint2)
        context.moveTo(xPointe, yPointe)
        context.lineTo(this.xCentre, this.yCentre)
      }
      context.strokeStyle = '#2B6CB9'
      context.stroke()

      context.beginPath()
      for (let alpha = 0; alpha < 4; alpha++) {
        const xPoint1 = this.xCentre + this.miniRayon * Math.cos(this.orientationRad - Math.PI / 4 + alpha * Math.PI / 2)
        const yPoint1 = this.yCentre + this.miniRayon * Math.sin(this.orientationRad - Math.PI / 4 + alpha * Math.PI / 2)
        const xPointe = this.xCentre + (this.rayon - this.sizeFont - this.marginFont * this.ratio) * Math.cos(this.orientationRad + alpha * Math.PI / 2)
        const yPointe = this.yCentre + (this.rayon - this.sizeFont - this.marginFont * this.ratio) * Math.sin(this.orientationRad + alpha * Math.PI / 2)
        const xPoint2 = this.xCentre + this.miniRayon * Math.cos(this.orientationRad + Math.PI / 4 + alpha * Math.PI / 2)
        const yPoint2 = this.yCentre + this.miniRayon * Math.sin(this.orientationRad + Math.PI / 4 + alpha * Math.PI / 2)
        context.moveTo(xPoint1, yPoint1)
        context.lineTo(xPointe, yPointe)
        context.lineTo(xPoint2, yPoint2)
        context.moveTo(xPointe, yPointe)
        context.lineTo(this.xCentre, this.yCentre)
      }
      context.fillStyle = '#ffffff'
      context.fill()
      context.strokeStyle = '#2B6CB9'
      context.stroke()
    },
    drawTextCardinal (context, angle, text) {
      context.font = '400 ' + this.sizeFont.toString() + 'px VAG Rounded Next'
      context.fillStyle = '#2B6CB9'
      context.textAlign = 'center'
      context.textBaseline = 'middle'

      const widthCardinal = Math.round(context.measureText(text).width)
      // On définit la distance minime comme la diagonale du text - la hauteur du texte / 2
      let distantMinText = Math.round(Math.sqrt(Math.pow(widthCardinal / 2, 2) + Math.pow(this.sizeFont / 2, 2))) - this.sizeFont / 2
      // On applique 0.5 * (cos(2x) + 1) à la distance minime pour garder la même distance en fonction de l'angle
      distantMinText = 0.5 * (Math.cos(2 * angle) + 1) * distantMinText + 0.65 * this.sizeFont

      const x = this.xCentre + (this.rayon - distantMinText) * Math.cos(angle)
      const y = this.yCentre + (this.rayon - distantMinText) * Math.sin(angle)
      context.fillText(text, x, y)
    }
  },
  watch: {
    orientationRad: function () {
      this.drawCompass()
    },
    ratio: function () {
      this.drawCompass()
    }
  },
  created () {
    window.addEventListener('resize', this.resizeCompass)
  },
  /**
    * @description  charge canvas et context pour dessin canvas, et récupère saison
  */
  mounted: function () {
    const that = this

    that.resizeCompass()
    /**
    * @description  charge WebFont pour police
    */
    WebFont.load({
      custom: {
        families: ['VAG Rounded Next:400']
      },
      active: function () {
        that.drawCompass()
      }
    })
  },
  /**
    * @description  charge WebFont pour police, une fois actif dessine
  */
  updated: function () {
    this.drawCompass()
  },
  unmounted () {
    window.removeEventListener('resize', this.resizeCompass)
  }
}
</script>
