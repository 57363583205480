<template>
    <canvas ref="canvasUdi" :width="Math.ceil(ratio * sizeCanvas)" :height="Math.ceil(ratio * sizeCanvas)" :style="{ height: sizeCanvas + 'px', width: sizeCanvas + 'px' }"></canvas>
</template>

<script>
export default {
  name: 'base-heatmap',
  props: {
    sizeCanvas: {
      type: Number,
      default: function () {
        return 400
      }
    },
    radius: {
      type: Number,
      default: function () {
        return 25
      }
    },
    heatmapPoints: {
      type: Array,
      default: function () {
        return []
      }
    },
    floor: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      ratio: window.devicePixelRatio,
      blur: 0.9,
      gradientConfig: {
        0.01: '#71BDFB',
        0.9: '#ffffff'
      },
      min: 200,
      max: 1000,
      circleCanvasRadius: 1,
      circleCanvas: undefined,
      gradientCanvas: undefined,
      gradient: undefined
    }
  },
  created () {
    window.addEventListener('resize', this.updateRatio)
  },
  updated () {
    this.updateRatio()
    this.draw()
  },
  mounted () {
    this.updateRatio()
    this.draw()
  },
  unmounted () {
    window.removeEventListener('resize', this.defineSizeCanvas)
  },
  watch: {
    ratio () {
      this.draw()
    },
    heatmapPoints () {
      this.draw()
    },
    sizeCanvas () {
      this.draw()
    },
    radius () {
      this.draw()
    },
    floor () {
      this.draw()
    }
  },
  methods: {
    updateRatio () {
      this.ratio = window.devicePixelRatio
    },
    draw () {
      const c = this.$refs.canvasUdi
      const width = Math.ceil(c.width)
      const height = Math.ceil(c.height)

      if (!this.circleCanvas) {
        this.createCircleBrushCanvas(this.radius)
      }
      if (!this.gradientCanvas) {
        this.createGradientCanvas(this.gradientConfig)
      }

      var ctx = c.getContext('2d')
      ctx.clearRect(0, 0, width, height)
      ctx.beginPath()
      ctx.globalCompositeOperation = 'source-over'

      // draw a grayscale heatmap by putting a blurred circle at each data point
      for (let i = 0, len = this.heatmapPoints.length, p; i < len; i++) {
        p = this.heatmapPoints[i]
        if (p.value > this.max) {
          p.value = this.max
        }
        var templateAlpha = (p.value - this.min) / (this.max - this.min)
        ctx.globalAlpha = templateAlpha < 0.01 ? 0.01 : templateAlpha
        ctx.drawImage(this.circleCanvas, p.x - this.circleCanvasRadius, p.y - this.circleCanvasRadius)
      }

      // colorize the heatmap, using opacity value of each pixel to get the right color from our gradient
      const colored = ctx.getImageData(0, 0, width, height)
      this.colorize(colored.data, this.gradient)
      ctx.putImageData(colored, 0, 0)

      ctx.beginPath()
      ctx.globalCompositeOperation = 'destination-atop'
      ctx.globalAlpha = 1
      ctx.moveTo(this.floor[0][0], this.floor[0][1])
      for (let j = 1; j < this.floor.length; j++) {
        ctx.lineTo(this.floor[j][0], this.floor[j][1])
      }
      ctx.fillStyle = '#71BDFB'
      ctx.fill()
    },
    createGradientCanvas (grad) {
      this.gradientCanvas = document.createElement('canvas')
      const ctx = this.gradientCanvas.getContext('2d')
      const gradient = ctx.createLinearGradient(0, 0, 0, 256)

      this.gradientCanvas.width = 1
      this.gradientCanvas.height = 256

      for (var key in grad) {
        gradient.addColorStop(key, grad[key])
      }

      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, 1, 256)

      this.gradient = ctx.getImageData(0, 0, 1, 256).data
    },
    createCircleBrushCanvas (r) {
      var blur = r * this.blur

      this.circleCanvas = document.createElement('canvas')
      var ctx = this.circleCanvas.getContext('2d')
      var r2 = this.circleCanvasRadius = r + blur

      this.circleCanvas.width = this.circleCanvas.height = r2 * 2

      ctx.shadowOffsetX = ctx.shadowOffsetY = r2 * 2
      ctx.shadowBlur = blur
      ctx.shadowColor = 'black'

      ctx.beginPath()
      ctx.arc(-r2, -r2, r, 0, Math.PI * 2, true)
      ctx.closePath()
      ctx.fill()
    },
    colorize (pixels, gradient) {
      for (let i = 0, len = pixels.length, j; i < len; i += 4) {
        j = pixels[i + 3] * 4 // get gradient color from opacity value

        if (j) {
          pixels[i] = gradient[j]
          pixels[i + 1] = gradient[j + 1]
          pixels[i + 2] = gradient[j + 2]
        }
      }
    }
  }
}
</script>
