<template>
  <div class="box">
    <div class="d-flex justify-content-between">
      <div class="title">Changer la simulation</div>
      <div><img class="weather" :src="weatherIcon.image" :title="weatherIcon.title" /></div>
    </div>
    <div class="subtitle">Saison</div>
    <div class="d-flex justify-content-between season">
      <div v-for="season in seasons" :key="season" :id="season" class="flex-grow-1" :class="{ 'selected' : season == seasonSelected}" @click.prevent="changeSeason">{{ seasonsString[season] }}</div>
    </div>
    <div class="subtitle d-flex justify-content-between">
      <div>Heure de la journée</div>
      <div class="hour-selected">{{ hourSelected }} h</div>
    </div>
    <div class="d-flex align-items-center">
      <div class="hours-day">{{ startDay }} h</div>
      <div class="flex-grow-1 mx-2">
        <input type="range" :min="startDay" :max="endDay" step="0.1" id="slider" v-model="sliderValue">
      </div>
      <div class="hours-day">{{ endDay }} h</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'the-property-page-slider-season',
  props: {
    seasonSelected: String,
    defaultHour: Number,
    seasonData: Object
  },
  data () {
    return {
      hourSelected: 13,
      sliderValue: 13,
      seasons: ['spring', 'summer', 'autumn', 'winter'],
      seasonsString: { spring: 'Printemps', summer: 'Eté', autumn: 'Automne', winter: 'Hiver' },
      weather: { 0: { image: require('../assets/img/weather/sunny.svg'), title: 'Ensoleillé' }, 1: { image: require('../assets/img/weather/sunny-cloudy.svg'), title: 'Partiellement ensoleillé' }, 2: { image: require('../assets/img/weather/cloudy.svg'), title: 'Nuageux' }, 3: { image: require('../assets/img/weather/very-cloudy.svg'), title: 'Très nuageux' } }
    }
  },
  computed: {
    weatherIcon () {
      if (this.seasonData == null || this.seasonData.cloud_cover < 3) {
        return this.weather[0]
      } else if (this.seasonData.cloud_cover < 5) {
        return this.weather[1]
      } else if (this.seasonData.cloud_cover < 7) {
        return this.weather[2]
      } else {
        return this.weather[3]
      }
    },
    startDay () {
      if (this.seasonData != null && 'daytime' in this.seasonData) {
        const startHour = this.seasonData.daytime.start
        return parseInt(startHour.substring(0, 2))
      } else {
        return 6
      }
    },
    endDay () {
      if (this.seasonData != null && 'daytime' in this.seasonData) {
        const endHour = this.seasonData.daytime.end
        return parseInt(endHour.substring(0, 2))
      } else {
        return 21
      }
    }
  },
  methods: {
    /**
      * @description  emit 'select' au composant parent pour changement saison, passe list à !list pour refermer, seasonActive prend la valeur, et slider repositionné sur 14h
    */
    changeSeason (event) {
      this.$emit('change-season', event.target.id)
    }
  },
  watch: {
    defaultHour: function () {
      this.sliderValue = this.defaultHour
    },
    sliderValue: function () {
      this.hourSelected = Math.round(this.sliderValue)
      this.$emit('change-hour', this.hourSelected)
    }
  }
}
</script>

<style scoped>
  .title {
    font-weight: 500;
    text-align: left;
  }

  .weather {
    height: 22px;
  }

  .box {
    background-color: white;
    border-radius: 10px;
    padding: 12px 18px;
    margin-bottom: 12px;
  }

  .season {
    margin-bottom: 4px;
  }

  .season > div {
    font-size: 14px;
    padding: 6px 0px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .season > div:hover {
    cursor: pointer;
    border-radius: 20px;
    background-color: #E5E6EB;
  }

  .season > div:first-child {
    margin-left: 0px;
  }

  .season > div:last-child {
    margin-right: 0px;
  }

  .season > .selected:hover {
    cursor: pointer;
    border-radius: 20px;
    background-color: #1C62B4;
  }

  .season > .selected {
    color: white;
    border-radius: 20px;
    background-color: #1C62B4;
  }

  .subtitle {
    text-align: left;
    font-size: 14px;
    padding: 6px 0px;
  }

  .hour-selected {
    font-size: 16px;
  }

  .hours-day {
    font-size: 12px;
  }

  #slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #1C62B4;
    outline: none;
  }

  #slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: white;
    border: 3px solid #333333;
    box-sizing: border-box;
    cursor: pointer;
  }

  #slider::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: white;
    border: 3px solid #333333;
    cursor: pointer;
  }

  @media (max-height: 700px) {
    .box {
        padding: 10px 18px;
    }

    .subtitle {
      padding: 3px 0px;
    }

    .season {
      margin-bottom: 3px;
    }
  }
</style>
