<template>
  <div class="container h-100 d-flex justify-content-center align-items-center">
    <div class="solen-box solen-shadow col-6">
      <h1 class="pb-4">Erreur 404</h1>
      <p>La page demandée n'a pas été trouvée.</p>
      <p>Si le problème perdure, vous pouvez nous <a href="https://www.solen.co/contact">contacter ici</a>.</p>
      <div class="text-center">
        <a href="https://www.solen.co">
        <img class="py-3" src="../assets/img/logo_solen.svg">
        <div>Visiter www.solen.co</div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.solen-box {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
}
</style>
