<template>
  <div ref="doughnut" class="doughnut">
    <canvas ref="canvas" :width="Math.ceil(ratio * widthCanvas)" :height="Math.ceil(ratio * heightCanvas)" :style="{ height: heightCanvas + 'px', width: widthCanvas + 'px' }"></canvas>
  </div>
</template>

<script>
import WebFont from 'webfontloader'

export default {
  name: 'base-diagram',
  props: {
    hourSelected: {
      type: Number,
      default: function () {
        return 13
      }
    },
    showNaturalLight: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    startDay: {
      type: Number,
      default: function () {
        return 6
      }
    },
    endDay: {
      type: Number,
      default: function () {
        return 21
      }
    },
    slots: {
      type: Object,
      default: function () {
        return { natural_light: [], sunlight: [] }
      }
    }
  },
  data () {
    return {
      heightCanvas: 80,
      widthCanvas: 160,
      ratio: window.devicePixelRatio,
      luminosityColor: '#008FC8',
      sunColor: '#F8CB15'
    }
  },
  computed: {
    durationDay: function () {
      return this.endDay - this.startDay
    },
    sunSlots: function () {
      return this.defineSlots(this.slots.sunlight)
    },
    luminositySlots: function () {
      return this.defineSlots(this.slots.natural_light)
    }
  },
  methods: {
    /**
    * @description  transforme un tableau d'horaire en tableau de booléen
    * @param        slotHours, tableau avec les horaires
    */
    defineSlots (slotHours) {
      const arraySlot = new Array(24).fill(false)

      for (const i in slotHours) {
        const slot = slotHours[i]
        if (slot.legend === 1) {
          const start = this.hh_mm_ss_to_hour(slot.start)
          const end = this.hh_mm_ss_to_hour(slot.end)
          for (let j = start; j < end; j++) {
            arraySlot[j] = true
          }
        }
      }

      return arraySlot
    },
    /**
    * @description  dessine flèches canvas
    * @param        canvasWidth, canvasHeight taille du canvas
    * @param        ratio ratio écran pour taille canvas
    * @param        hourValue valeur heure
    * @param        color couleur à utiliser
    * @param        hourAverage moyenne de la ville
    */
    drawDoughnut () {
      const ctx = this.$refs.canvas.getContext('2d')
      const canvasHeight = this.$refs.canvas.height
      const canvasWidth = this.$refs.canvas.width
      ctx.clearRect(0, 0, canvasWidth, canvasHeight)

      const sizeFont = 0.02 * canvasHeight + 12.4 * this.ratio
      const margin = 0 * this.ratio
      const lineWidth = 0.03 * canvasHeight + 5.6 * this.ratio
      const rayonHour = lineWidth + 4 * this.ratio

      var colorHourFill = 'white'
      var colorHourStroke = 'white'
      var xHour = 0
      var yHour = 0
      var angleHour = 0

      // Text
      ctx.font = sizeFont.toString() + 'px VAG Rounded Next'
      ctx.fillStyle = '#000000'
      const widthText = Math.round(ctx.measureText('00h00').width)
      const rayon = Math.min(canvasWidth / 2 - widthText - margin, canvasHeight - sizeFont - margin - rayonHour)

      const angle = Math.PI / this.durationDay
      const xCircle = canvasWidth / 2
      const yCircle = canvasHeight
      const colorCircle = '#cfd8dc'
      let currentColor = colorCircle

      // On parcourt la journée
      for (let i = this.startDay; i <= this.endDay; i++) {
        ctx.beginPath()
        ctx.arc(xCircle, yCircle, rayon, angle * (i - this.startDay) - Math.PI, angle * ((i - this.startDay) + 1) - Math.PI)

        ctx.lineWidth = lineWidth
        if (this.luminositySlots[i] === true && this.showNaturalLight) {
          // Créneaux de luminosité
          currentColor = this.luminosityColor
        } else {
          currentColor = colorCircle
        }
        ctx.strokeStyle = currentColor
        ctx.stroke()

        if (this.sunSlots[i] === true) {
          // Créneaux d'ensoleillement
          let rayonSunlight = rayon + 1 / 2 * lineWidth
          let lineWidthSunlight = 1 / 2 * lineWidth
          if (this.showNaturalLight === false) {
            rayonSunlight = rayon
            lineWidthSunlight = lineWidth
          }
          ctx.beginPath()
          ctx.arc(xCircle, yCircle, rayonSunlight, angle * (i - this.startDay) - Math.PI, angle * ((i - this.startDay) + 1) - Math.PI)
          ctx.lineWidth = lineWidthSunlight
          currentColor = this.sunColor
          ctx.strokeStyle = currentColor
          ctx.stroke()
        }

        // On trace les traits de raccordement
        if (this.sunSlots[i] === true && this.luminositySlots[i] === true && this.showNaturalLight) {
          ctx.beginPath()
          ctx.strokeStyle = this.luminosityColor
          ctx.lineWidth = this.ratio
          ctx.moveTo(xCircle + (rayon - lineWidth / 2) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon - lineWidth / 2) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.lineTo(xCircle + (rayon + 1 / 4 * lineWidth) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + 1 / 4 * lineWidth) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.stroke()

          ctx.beginPath()
          ctx.strokeStyle = this.sunColor
          ctx.lineWidth = this.ratio
          ctx.moveTo(xCircle + (rayon + 1 / 4 * lineWidth) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + 1 / 4 * lineWidth) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.lineTo(xCircle + (rayon + 3 / 4 * lineWidth) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + 3 / 4 * lineWidth) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.stroke()
        } else if (this.sunSlots[i] === true && this.luminositySlots[i] === false && this.showNaturalLight) {
          ctx.beginPath()
          ctx.strokeStyle = colorCircle
          ctx.lineWidth = this.ratio
          ctx.moveTo(xCircle + (rayon - lineWidth / 2) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon - lineWidth / 2) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.lineTo(xCircle + (rayon + 1 / 4 * lineWidth) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + 1 / 4 * lineWidth) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.stroke()

          ctx.beginPath()
          ctx.strokeStyle = this.sunColor
          ctx.lineWidth = this.ratio
          ctx.moveTo(xCircle + (rayon + 1 / 4 * lineWidth) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + 1 / 4 * lineWidth) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.lineTo(xCircle + (rayon + 3 / 4 * lineWidth) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + 3 / 4 * lineWidth) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.stroke()
        } else {
          ctx.beginPath()
          ctx.lineWidth = this.ratio
          ctx.moveTo(xCircle + (rayon - lineWidth / 2) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon - lineWidth / 2) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.lineTo(xCircle + (rayon + lineWidth / 2) * Math.cos(angle * (i - this.startDay) - Math.PI), yCircle + (rayon + lineWidth / 2) * Math.sin(angle * (i - this.startDay) - Math.PI))
          ctx.stroke()
        }

        // Heure
        if (i === this.hourSelected) {
          angleHour = angle * (i - this.startDay)
          xHour = xCircle + rayon * Math.cos(angleHour - Math.PI)
          yHour = yCircle + rayon * Math.sin(angleHour - Math.PI)
          colorHourStroke = currentColor
          if (i === this.startDay || i === this.endDay) {
            colorHourFill = currentColor
          } else {
            colorHourFill = 'white'
          }
        }
      }

      ctx.beginPath()
      ctx.arc(xHour, yHour, 3 / 4 * lineWidth, 0, 2 * Math.PI)
      ctx.strokeStyle = colorHourStroke
      ctx.lineWidth = lineWidth
      ctx.fillStyle = colorHourFill
      ctx.stroke()
      ctx.fill()

      // Texte de l'heure
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      let text = this.hourSelected.toString()
      if (this.hourSelected < 10) {
        text = '0' + text
      }
      text = text + ' h'
      ctx.font = sizeFont.toString() + 'px VAG Rounded Next'
      ctx.fillStyle = '#000000'
      const widthHour = Math.round(ctx.measureText(text).width)
      // On définit la distance minime comme la diagonale du text - la hauteur du texte / 2
      let distantMinText = Math.round(Math.sqrt(Math.pow(widthHour / 2, 2) + Math.pow(sizeFont / 2, 2))) - sizeFont / 2
      // On applique 0.5 * (cos(2x) + 1) à la distance minime pour garder la même distance en fonction de l'angle
      distantMinText = 0.5 * (Math.cos(2 * angleHour) + 1) * distantMinText + 0.75 * sizeFont

      const xText = xCircle + (rayon + lineWidth + distantMinText) * Math.cos(angleHour - Math.PI)
      let yText = yCircle + (rayon + lineWidth + distantMinText) * Math.sin(angleHour - Math.PI)

      if (this.hourSelected === this.startDay || this.hourSelected === this.endDay) {
        yText = yText - sizeFont / 2
      }
      ctx.fillText(text, xText, yText)
    },
    /**
      * @description  transforme données en HH:mm:ss
      * @param        value
    */
    data_to_hh_mm_ss (value) {
      return new Date((value + 360) * 1000 * 60).toISOString().substr(11, 5).replace(':', 'h')
    },
    /**
      * @description  extrait l'heure de HH:mm:ss en int
      * @param        value
    */
    hh_mm_ss_to_hour (value) {
      const a = value.split(':')
      return parseInt(a[0])
    },
    resizeDoughtnut () {
      this.ratio = window.devicePixelRatio
      if (this.$refs && this.$refs.doughnut) {
        this.heightCanvas = this.$refs.doughnut.clientHeight
        this.widthCanvas = this.$refs.doughnut.clientWidth
      } else {
        this.heightCanvas = 0
        this.widthCanvas = 0
      }
    }
  },
  watch: {
    hourSelected: function () {
      this.drawDoughnut()
    },
    seasonSelected: function () {
      this.drawDoughnut()
    },
    sunSlots: function () {
      this.drawDoughnut()
    },
    luminositySlots: function () {
      this.drawDoughnut()
    },
    startDay: function () {
      this.drawDoughnut()
    },
    endDay: function () {
      this.drawDoughnut()
    },
    ratio: function () {
      this.drawDoughnut()
    }
  },
  created () {
    window.addEventListener('resize', this.resizeDoughtnut)
  },
  /**
    * @description  charge canvas et context pour dessin canvas, et récupère saison
  */
  mounted: function () {
    const that = this

    that.resizeDoughtnut()
    /**
    * @description  charge WebFont pour police
    */
    WebFont.load({
      custom: {
        families: ['VAG Rounded Next:400', 'VAG Rounded Next:700']
      },
      active: function () {
        that.drawDoughnut()
      }
    })
  },
  unmounted () {
    window.removeEventListener('resize', this.resizeDoughtnut)
  },
  /**
    * @description  charge WebFont pour police, une fois actif dessine camembert
  */
  updated: function () {
    this.drawDoughnut()
  }
}
</script>

<style scoped>
  .doughnut {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
</style>
