<template>
  <div class="box" v-if="hasSunshine || hasNaturalLight">
    <div class="title">Horaires journaliers</div>
    <div class="d-flex flex-row align-content-stretch justify-content-between">
      <div class="d-flex flex-column justify-content-end legend">
        <div v-if="hasSunshine" class="sun">Ensoleillement direct</div>
        <div v-if="hasNaturalLight && showNaturalLight" class="natural_light">Luminosité naturelle</div>
      </div>
      <div class="diagram flex-grow-1">
        <base-diagram :hour-selected="hourSelected"
                  :slots="slots"
                  :start-day="startDay"
                  :show-natural-light="showNaturalLight"
                  :end-day="endDay"
                  ></base-diagram>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDiagram from './BaseDiagram.vue'

export default {
  components: { BaseDiagram },
  name: 'the-property-page-room-diagram',
  props: {
    hourSelected: {
      type: Number,
      default: function () {
        return 13
      }
    },
    showNaturalLight: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    defaultHour: {
      type: Number,
      default: function () {
        return 13
      }
    },
    seasonData: Object,
    slots: {
      type: Object,
      default: function () {
        return { natural_light: [], sunlight: [] }
      }
    }
  },
  computed: {
    hasSunshine () {
      for (let i = 0; i < this.slots.sunlight.length; i++) {
        if (this.slots.sunlight[i].legend === 1) {
          return true
        }
      }
      return false
    },
    hasNaturalLight () {
      for (let i = 0; i < this.slots.natural_light.length; i++) {
        if (this.slots.natural_light[i].legend === 1) {
          return true
        }
      }
      return false
    },
    startDay () {
      if (this.seasonData != null && 'daytime' in this.seasonData) {
        const startHour = this.seasonData.daytime.start
        return parseInt(startHour.substring(0, 2))
      } else {
        return 6
      }
    },
    endDay () {
      if (this.seasonData != null && 'daytime' in this.seasonData) {
        const endHour = this.seasonData.daytime.end
        return parseInt(endHour.substring(0, 2))
      } else {
        return 21
      }
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .box {
    background-color: white;
    border-radius: 10px;
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 18px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: stretch;
  }

  .legend {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  .legend > div:first-child {
    margin-bottom: 6px;
  }

  .sun {
    color: #F8CB15;
  }

  .natural_light {
    color: #008FC8;
  }

  .diagram {
    position: relative;
    height: 110px;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }

  @media (max-height: 700px) {
    .diagram {
      height: 100px;
    }
  }

  @media (max-height: 680px) {
    .box {
      padding: 10px 18px;
      margin-top: 10px;
    }

    .diagram {
      height: 90px;
    }
  }

  @media (max-height: 640px) {
    .diagram {
      height: 75px;
    }

    .legend {
      font-size: 14px;
      line-height: 16px;
    }

    .legend > div:first-child {
      margin-bottom: 4px;
    }
  }
</style>
