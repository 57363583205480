<template>
  <router-view></router-view>
</template>

<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

export default {
  name: 'App'
}
</script>

<style>
@import 'assets/css/cityscan-icon.css';
@import 'assets/css/cityscan-icon-ff.css';

@font-face {
  font-family: 'VAG Rounded Next';
  font-weight: 300;
  src: url('assets/fonts/monotype-light.otf');
}
@font-face {
  font-family: 'VAG Rounded Next';
  font-weight: 400;
  src: url('assets/fonts/monotype-regular.otf');
}
@font-face {
  font-family: 'VAG Rounded Next';
  font-weight: 500;
  src: url('assets/fonts/monotype-medium.otf');
}
@font-face {
  font-family: 'VAG Rounded Next';
  font-weight: 700;
  src: url('assets/fonts/monotype-bold.otf');
}

body {
  font-family: 'VAG Rounded Next', sans-serif;
  color: #333333;
  background-color: #F1F7FD;
}

.no-cursor {
  cursor: default !important;
}

.solen-shadow {
  box-shadow: 0 0rem 0.3rem rgba(0,0,0,.15)!important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 2px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BDC1C6;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8D9094;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  100% {
     opacity: 1;
  }
}
</style>
